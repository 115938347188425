<template>
  <div class="menu">
    <div class="container p-4" >
      <h3 style="font-size: 1.2em;" class="text-center">解答状況</h3>

      <div class="d-flex">
        <div class="menu1">
          <div class="menuWr">
              <div
              v-for="(v, i) in questions" :key="v.id"
              class="qF2T4n9Q menuori"
              @click="jump(i)"
              :class="{
                'cN2j8tvd': v.isBookmark,
                'kG3HjYZN': v.currentChoice > 0,
                'M3q2bQUP': i == index
              }"
              >{{ v.no }}</div>
            </div>
        </div>
        
        <div class="menu2">
          <div class="text-center mb-3">
            <div class="menuori"></div>
            未解答
          </div>
          <div class="text-center mb-3">
            <div class="menuori kG3HjYZN"></div>
            解答済み
          </div>
          <div class="text-center mb-3">
            <div class="menuori cN2j8tvd"></div>
            後で見直す
          </div>
          <div class="text-center mb-3">
            <div class="menuori M3q2bQUP"></div>
            解答中
          </div>
        </div>
      </div>
      <div class=" mt-5 text-center"><button class="btn btn-primary btn-primary btn-primary btn-primary btn-primary btn-primary btn-primary D4ckn6yw" @click="close">閉じる</button></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import Question from "../models/question";
import StorageService from "../services/storage";

@Component
export default class Menu extends Vue {
  @Prop()
  private index!: number;
  @Prop()
  public questions!: Question[]

  created() {

  }
  mounted() { }


  @Emit("close")
  close() {
    return
  }

  private jump(i: number) {
    if (i < 0) return;
    if (i == this.index) return
    let next: any = this.questions[i];
    this.$router.push(`/exam/${next.shikenId}/${next.no}`, () => { });
  }
}
</script>
