<template>
  <div class="r6TtFRdJ2">
    <div class="main2">
      <h3>{{getShikenTitle()}}</h3>
      <div class="d-flex resultview align-items-center mt-4">
      
        <div style="width: 200px">合計得点</div>
        <div style="flex:1"><span class="score">{{ score }}</span>点</div>
        <div class="">
          <span v-if="isPass" class="pass">合格</span>
          <span v-else class="pass">不合格</span>
        </div>
      </div>

      <div class="mt-3">
        <h3 style="font-size: 1.1em;">解答一覧</h3>
        <div class="row" >
          <div class="col-4">
            <table class="listTable">
              <tr>
                <td>問題</td>
                <td class="text-center" style="width: 120px">あなたの解答</td>
                <td class="text-center" style="width: 120px">正解</td>
              </tr>
              <tr v-for="(v, i) in questions" :key="v.id" v-if="i < 20" :class="{ 'sZ5iBCme': v.currentChoice != v.answer }">
                <td class="text-center">{{ v.no }}</td>
                <td class="text-center">{{ getChoiceString(v) }}</td>
            <td class="text-center">{{ getAnswerString(v) }}</td>
              </tr>
            </table>
          </div>
          <div v-if="shikenType == 1" class="col-4">
            <table class="listTable">
              <tr>
                <td>問題</td>
                <td class="text-center" style="width: 120px">あなたの解答</td>
                <td class="text-center" style="width: 120px">正解</td>
              </tr>
              <tr v-for="(v, i) in questions" :key="v.id" v-if="i >= 20 && i < 40" :class="{ 'sZ5iBCme': v.currentChoice != v.answer }">
                <td class="text-center">{{ v.no }}</td>
                <td class="text-center">{{ getChoiceString(v) }}</td>
            <td class="text-center">{{ getAnswerString(v) }}</td>
              </tr>
            </table>
          </div>
          <div v-if="shikenType == 1" class="col-4">
            <table class="listTable">
              <tr>
                <td>問題</td>
                <td class="text-center" style="width: 120px">あなたの解答</td>
                <td class="text-center" style="width: 120px">正解</td>
              </tr>
              <tr v-for="(v, i) in questions" :key="v.id" v-if="i >= 40" :class="{ 'sZ5iBCme': v.currentChoice != v.answer }">
                <td class="text-center">{{ v.no }}</td>
                <td class="text-center">{{ getChoiceString(v) }}</td>
            <td class="text-center">{{ getAnswerString(v) }}</td>
              </tr>
            </table>
          </div>
        </div>

      </div>
    </div>
    <div class="footer">
      <hr class="m-0" />
      <div class="hB6kQJPA align-items-center">
        <div class="text-end">
          <a v-if="shikenId < 7" :href="'/pdf/l' + String(shikenId) + '.pdf'" target="_blank" class="btn btn-primary D4ckn6yw Gb7qXjW3 mx-3 mt-3" style="width:200px">解答・解説PDF</a>
          <button @click="endExam" class="btn btn-primary D4ckn6yw Gb7qXjW3 mx-3 mt-3" style="width:200px">終了</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit } from "vue-property-decorator";
import Question from "../models/question";
import StorageService from "../services/storage";
import BasicUtil from "../services/basicUtil";
import Shiken from "../models/shiken";
import HttpClient from "../services/httpClient";

@Component({
  components: {}
})
export default class ResultView extends Vue {
  public questions: Question[] = [];
  private grade: number = 3;
  private all: number = 0;
  private score: number = 0;
  private scores: number[] = [0, 0, 0, 0, 0, 0]
  private isPass: boolean = false;
  private shikenId: number = 0
  private shikenType: number = 1
  private shikenName: string = ""
  created() {
    history.pushState(null, "", null);
    this.questions = this.$store.state.data;
    if (this.questions.length == 0) return (location.href = "/");

    const question = this.questions[0];
    const shiken: Shiken | null = new HttpClient().getShiken(
      Number(question.shikenId)
    );
    if (shiken == null) return (location.href = "/");
    this.shikenId = shiken.id
    this.shikenName = shiken.name
    this.shikenType = question.answerType
    this.grade = shiken.grade;
    const vm = this;
    this.questions.forEach((q: Question) => {
      vm.all += q.score;
      if (q.result) vm.score += q.score;
    });
    if (this.score >= this.all * 0.6) {
      this.isPass = true;
    }
  }
  mounted() { }
  getShikenTitle() {
    return this.shikenName == "学科試験" ? "学科試験" : "実技試験 " + this.shikenName
  }
  close() {
    location.href = `/`
  }


  isIncorrect(question: Question | undefined) {
    if (question == undefined) return false;
    return !question.result;
  }


  getDisplayAnswer(num: number, question: Question) {
    if (question.choice1 == "○" && question.choice2 == "✕") {
      if (num == 1) return "○"
      if (num == 2) return "×"
      return "-"
    }
    return num
  }


  private endExam() {
    
    if (!window.confirm("終了しますか？")) return
    return location.href = "/"
  }

  getChoiceString(q: Question) {
    if (q.currentChoice == undefined) return ""
    if (q.choice1 == "〇") {
      return q.currentChoice == 1 ? "〇" : "×"
    }
    return String(q.currentChoice)
  }

  getAnswerString(q: Question) {
    if (q.answer == undefined) return ""
    if (q.choice1 == "〇") {
      return q.answer == 1 ? "〇" : "×"
    }
    return String(q.answer)
  }
}
</script>
