import Question from '@/models/question';
import axios from 'axios'
import BasicUtil from './basicUtil'
import question from './question'
import shiken from './shiken';
import Shiken from '@/models/shiken';

export default class HttpClient {



  public getQuestion(): any {
    if (process.env.VUE_APP_GRADE == '3') {
      return JSON.parse(JSON.stringify(question))
    }
    return JSON.parse(JSON.stringify(question))
  }

  public getShiken(id: number): Shiken|null {
    const shikens = this.getShikens()
    const ret = shikens.find(s => {
      return s.id == id
    })
    return ret ?? null
  }

  public getShikens(): Shiken[] {
    return shiken as unknown as Shiken[]
  }


  public getQuestionsFromAnswerType(shikenId: number, limit = 9999): Question[] {
    let questions: Question[] = this.getQuestion()
    questions = questions.filter(q => {
      return q.shikenId == shikenId
    })
    return questions.slice(0, limit);
  }

  shuffle<T>(array: T[]) {
    const out = Array.from(array);
    for (let i = out.length - 1; i > 0; i--) {
      const r = Math.floor(Math.random() * (i + 1));
      const tmp = out[i];
      out[i] = out[r];
      out[r] = tmp;
    }
    return out;
  }



  public getQuestionFromId(id: number) {
    let questions: Question[] = this.getQuestion()
    const shikens: Shiken[] = this.getShikens()
    const question = questions.find(q => {
      return q.id == id
    })
    if (question == undefined) return null
    const shiken = shikens.find(c => {
      return c.id == question?.shikenId
    })
    if (shiken) question!.shiken = shiken
    return question
  }
}
