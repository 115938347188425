<template>
  <div class="">
    <div class="mb-3"><div class="G6zckbvr " :class="{'eJ7LMsiQ': questions[index].isBookmark}"></div>問{{question.no}}</div>
    <!-- <p :class="'qi-' + question.id" v-html="question.question1"></p> -->
    <p :class="'qi-' + question.id" v-html="question.question2"></p>
    <ol class="Ar2jysLK" :class="{'Gm8W5aZb': questions[index].isBookmark}">
      <li v-for="n in 4" :key="n">
        <div v-if="question['choice' + String(n)] && question['choice' + String(n)] != ''">
          <label class="d-flex align-items-start">
            <input class="" type="radio" name="select" @change="answer(n)" :checked="question['currentChoice'] == n">
            <div v-if="question['choice1'] != '〇'" class="s4NVRcXx">{{ n }}.</div>
            <div v-else class="nimnim"></div>
            <div class="Ki9LFtxd" v-html="question['choice' + String(n)]"></div>
          </label>
        </div>
      </li>
    </ol>
    <div style="height: 250px;"></div>
  </div>
</template>


<script lang="ts">
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import Question from "../../models/question";
import StorageService from "../../services/storage";
import question from "@/services/question";
@Component({
  components: {}
})
export default class Choice extends Vue {
  @Prop()
  private question!: Question;
  @Prop()
  private index!: number;
  @Prop()
  private indexs!: number[];
  @Prop()
  public questions!: Question[]

  private isLock: boolean = false;
  mounted() {}


  @Emit("answerEmit")
  answerEmit () {
    return
  }

  public async answer(i: number) {
    this.questions[this.index].result = this.question.answer == i;
    this.questions[this.index].currentChoice = i;
    this.$store.commit("setQuestions", this.questions);
    this.answerEmit();
  }
}
</script>
