
export default [
{
  "id": 3,
  "name": "学科試験",
  "no": 3,
  "grade": 3,
  "time": 90
},
{
  "id": 4,
  "name": "資産設計提案業務",
  "no": 3,
  "grade": 3,
  "time": 60
},
{
  "id": 5,
  "name": "個人資産相談業務",
  "no": 3,
  "grade": 3,
  "time": 60
},
{
  "id": 6,
  "name": "保険顧客資産相談業務",
  "no": 3,
  "grade": 3,
  "time": 60
},
{
  "id": 7,
  "name": "学科試験",
  "no": 3,
  "grade": 3,
  "time": 90
},
{
  "id": 8,
  "name": "資産設計提案業務",
  "no": 3,
  "grade": 3,
  "time": 60
},
{
  "id": 9,
  "name": "個人資産相談業務",
  "no": 3,
  "grade": 3,
  "time": 60
},
{
  "id": 10,
  "name": "保険顧客資産相談業務",
  "no": 3,
  "grade": 3,
  "time": 60
},
]