<template>
  <div class="r6TtFRdJ" style="position: relative">
    <div class="main" :style="isWrapScroll ? 'overflow-y: scroll;' : ''">
      <Calc v-show="isVisibleCalc" class="calculators" id="calculators" :isInput="isVisibleCalc" />
      <component class="mb-5" v-if="current != null" :is="current" :question="question" :questions="questions"
        :index="index" :indexs="indexs" @answerEmit="answer" @next="next" @prev="prev" />
      <div v-else></div>
    </div>


    <div class="footer">
      <hr class="m-0" />
      <div class="hB6kQJPA d-flex align-items-center">
        <div class="flex-fill">
          <button class="btn btn-primary D4ckn6yw Gb7qXjW3" @click="openMenu">解答状況</button>
          <button @click="openCalc" class="btn btn-primary D4ckn6yw Gb7qXjW3 mx-3">電卓</button>
        </div>
        <div>
          <button @click="end" class="btn btn-primary D4ckn6yw Gb7qXjW3">試験終了</button>
          <button @click="bookmark" class="btn btn-primary D4ckn6yw Gb7qXjW3 mx-3">後で見直す</button>
          <button @click="prev" class="btn btn-primary D4ckn6yw Gb7qXjW3 nextprev" :disabled="isFirst">前へ</button>
          <button @click="next" class="btn btn-primary D4ckn6yw Gb7qXjW3 nextprev mx-3" :disabled="isLast">次へ</button>
        </div>
      </div>
    </div>
    <Menu v-if="isOpenMenu" @close="close" :questions="questions" :index="index" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import Question from "../models/question";
import StorageService from "../services/storage";
import HttpClient from "../services/httpClient";
import BasicUtil from "../services/basicUtil";
import Shiken from "../models/shiken";
import Menu from "@/components/Menu.vue"
import Calc from "@/components/Calc.vue"

@Component({
  components: {
    Menu,
    Calc
  }
})
export default class QuestionPage extends Vue {
  public current: object | null = null;
  private id: string = this.$route.params.id ?? "0";
  private shikenId: string = this.$route.params.shikenId ?? "0";
  private index: number = 0;
  private indexs: number[] = [];
  public questions: Question[] = [];
  private question: Question = new Question();
  private isEnd: boolean = true;
  private isFirst: boolean = false;
  private isLast: boolean = false;
  private isOpenMenu: boolean = false
  private isVisibleCalc: boolean = false
  private isWrapScroll: boolean = false

  created() {
    history.pushState(null, "", null);

    const shiken: Shiken | null = new HttpClient().getShiken(
      Number(this.shikenId)
    );
    if (shiken == null) return new BasicUtil().backtoTop();
    // const isAuth = BasicUtil.isAuth(String(shiken!.grade));
    // if (!isAuth) return (location.href = "/");
    this.questions = this.$store.state.data;
    if (this.questions.length <= 0) return new BasicUtil().backtoTop();

    this.isEnd = false;
    window.scrollTo(0, 0);

    const vm = this;

    this.index = Number(vm.id) - 1;
    this.$store.commit("setIndex", this.index);
    if (this.index == 0) this.isFirst = true;
    this.question = this.questions[this.index];
    this.isWrapScroll = this.question.answerType == 1
    if (this.index >= this.questions.length - 1) this.isLast = true;

    this.setCurrent(this.question.id, this.question.answerType);
  }

  mounted() { }

  private setCurrent(id: number, type: number) {
    let current = null;
    if (type == 1) {
      current = require("./questions/choice.vue");
    }
    if (type == 2) {
      current = require("./questions/double.vue");
    }
    if (type == 3) {
      current = require("./questions/double2.vue");
    }
    this.current = current.default;
  }

  private prev() {
    if (this.index <= 0) {
      return;
    }

    let prev: any = this.questions[this.index - 1];

    this.$router.push(`/exam/${prev.shikenId}/${prev.no}`, () => { });
  }

  private next() {
    if (this.index >= this.questions.length - 1) {
      return;
    }

    let next: any = this.questions[this.index + 1];
    this.$router.push(`/exam/${next.shikenId}/${next.no}`, () => { });
  }

  private jump(i: number) {
    if (i < 0) return;
    let next: any = this.questions[i];
    this.$router.push(`/exam/${next.shikenId}/${next.no}`, () => { });
  }

  private bookmark() {
    if (this.questions[this.index].isBookmark == undefined) this.questions[this.index].isBookmark = false
    this.questions[this.index].isBookmark = !this.questions[this.index]
      .isBookmark;

    this.$store.commit("setQuestions", this.questions);
    this.questions = JSON.parse(JSON.stringify(this.$store.state.data));
  }

  public async answer() {
    this.questions = JSON.parse(JSON.stringify(this.$store.state.data));
  }

  public openMenu() {
    this.isOpenMenu = !this.isOpenMenu
  }

  public close() {
    this.isOpenMenu = false
  }

  private end() {
    if (!confirm("終了しますか？")) return;
    if (this.$store.state.isRe) {
      this.$router.push(`/`);
    } else {
      this.$router.push(`/end`);
    }
  }

  public openCalc() {
    this.isVisibleCalc = !this.isVisibleCalc
  }

  // public overtime() {
  //   const vm = this
  //   this.isEnd = true;
  //   setTimeout(function() {
  //     alert("制限時間が終了しました");
  //     vm.$router.push(`/result`);
  //   }, 500);
  // }
}
</script>

