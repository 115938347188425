<template>
  <div class="timer" >
    <span class="">残り時間 </span>
    <span class="" v-if="!isEnd && !$store.state.isRe">{{ timerStr }}</span>
    <span class="" v-else>ー</span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import StorageService from "../services/storage";

@Component
export default class Timer extends Vue {
  private isEnd: boolean = false;
  private interval: any = null;
  private goalDate: Date = new Date();
  private timerStr: string = "";

  mounted() {
    if (this.$store.state.isRe) return
    this.start();
  }

  start() {
    if (this.$store.state.goal == 0) return
    this.goalDate = new Date(this.$store.state.goal);
    const vm = this;
    this.setTimer();
    this.interval = setInterval(function() {
      const counter = vm.setTimer();
      if (counter[0] <= 0 && counter[1] <= 0) {
        clearInterval(vm.interval);
        vm.timerStr = "終了"
        vm.overTime(0);
        vm.stop()
        vm.isEnd = true;
      }
    }, 200);
  }

  public stop() {
    if (this.interval == null) return
    clearInterval(this.interval);
    this.interval = null
  }

  countdown(due: Date) {
    var now = new Date();
    var rest = due.getTime() - now.getTime();
    this.overTime(Math.floor(rest));
    var sec = Math.floor((rest / 1000) % 60);
    var min = Math.floor(rest / 1000 / 60);
    var count = [min, sec];
    return count;
  }

  setTimer() {
    var counter = this.countdown(this.goalDate);
    let minutes = String(counter[0])
    if (counter[0] < 100) minutes = ("0" + String(counter[0])).slice(-2)
    let timerStr =
      minutes +
      ":" +
      ("0" + String(counter[1])).slice(-2);
    if (timerStr != "00:00") {
      this.timerStr = timerStr;
    }
    return counter;
  }

  overTime(rest: number) {
    if (rest <= 0) {
      this.overtime();
    }
  }

  @Emit("end")
  overtime() {
    return;
  }
}
</script>

<style scoped>
.timer {
  font-size: 1.1em;
  text-align: right;
  font-weight: bold;
}
</style>