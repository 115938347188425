
export default [
    {
        "id": 121,
        "shikenId": 3,
        "no": 1,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "ファイナンシャル・プランナーが顧客と投資顧問契約を締結し、当該契約に基づき金融商品取引法で定める投資助言・代理業を行うためには、内閣総理大臣の登録を受けなければならない。(2022.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 122,
        "shikenId": 3,
        "no": 2,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "公的介護保険の第2号被保険者は、市町村または特別区の区域内に住所を有する65歳以上の者である。(2021.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 123,
        "shikenId": 3,
        "no": 3,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "国民年金の第1号被保険者は、日本国内に住所を有する20歳以上60歳未満の自営業者や学生などのうち、日本国籍を有する者のみが該当する。(2023.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 124,
        "shikenId": 3,
        "no": 4,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "遺族基礎年金を受給することができる遺族は、国民年金の被保険者等の死亡の当時、その者によって生計を維持され、かつ、所定の要件を満たす「子のある配偶者」または「子」である。(2019.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 125,
        "shikenId": 3,
        "no": 5,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "日本学生支援機構の奨学金（貸与型）のうち、第一種奨学金は利子が付かない。(2021.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 126,
        "shikenId": 3,
        "no": 6,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "保険業法上の保険募集において、保険募集人が保険契約の締結の媒介を行う場合、保険募集人が保険契約の申込みに対して承諾した時点で当該保険契約は有効に成立する。(2021.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 127,
        "shikenId": 3,
        "no": 7,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "逓減定期保険は、保険期間の経過に伴い保険料が所定の割合で減少するが、死亡保険金額は保険期間を通じて一定である。(2023.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 128,
        "shikenId": 3,
        "no": 8,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "自動車保険の人身傷害保険では、被保険者が被保険自動車を運転中、自動車事故により負傷した場合、損害額から自己の過失割合に相当する部分を差し引いた金額が補償の対象となる。(2023.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 129,
        "shikenId": 3,
        "no": 9,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "国内旅行傷害保険では、一般に、国内旅行中にかかった細菌性食中毒は補償の対象とならない。(2022.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 130,
        "shikenId": 3,
        "no": 10,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "リビング・ニーズ特約は、被保険者の余命が6カ月以内と判断された場合に、所定の範囲内で死亡保険金の一部または全部を生前に受け取ることができる特約である。(2021.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 131,
        "shikenId": 3,
        "no": 11,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "全国企業短期経済観測調査（日銀短観）は、企業間で取引される財に関する価格の変動を測定した統計である。(2023.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 132,
        "shikenId": 3,
        "no": 12,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "一般に、日本の金利が一定のときに米国の金利が低下すると、米ドルを円に換える動きが強まり、円安ドル高が進行する要因となる。(2020.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 133,
        "shikenId": 3,
        "no": 13,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "インデックスファンドは、日経平均株価や東証株価指数（TOPIX）などの特定の指標に連動することを目指して運用されるファンドである。(2022.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 134,
        "shikenId": 3,
        "no": 14,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "上場株式の売買において、普通取引は約定日の翌営業日に決済が行われる。(2021.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 135,
        "shikenId": 3,
        "no": 15,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "2024年以降のNISA制度のつみたて投資枠において、上場株式は投資対象商品とされていない。(2022.09改題)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 136,
        "shikenId": 3,
        "no": 16,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税において、老齢基礎年金や老齢厚生年金を受け取ったことによる所得は、非課税所得となる。(2023.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 137,
        "shikenId": 3,
        "no": 17,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "個人が国内において支払を受ける預貯金の利子は、原則として、20.315％の税率により所得税および復興特別所得税と住民税が源泉徴収等され、課税関係が終了する。(2022.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 138,
        "shikenId": 3,
        "no": 18,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "不動産所得の金額の計算上生じた損失の金額のうち、不動産所得を生ずべき業務の用に供する土地を取得するために要した負債の利子の額に相当する部分の金額は、損益通算の対象とならない。(2022.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 139,
        "shikenId": 3,
        "no": 19,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "所得税において、生計を一にする配偶者の合計所得金額が48万円を超える場合、配偶者控除の適用を受けることはできない。(2023.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 140,
        "shikenId": 3,
        "no": 20,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "給与所得者は、年末調整により、所得税の医療費控除の適用を受けることができる。(2022.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 141,
        "shikenId": 3,
        "no": 21,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "土地の登記記録の表題部には、所有権に関する事項が記録される。(2021.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 142,
        "shikenId": 3,
        "no": 22,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "不動産の売買契約において、買主が売主に解約手付を交付した場合、売主は、買主が契約の履行に着手するまでは、受領した解約手付を買主に返還することで、契約の解除をすることができる。(2022.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 143,
        "shikenId": 3,
        "no": 23,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "都市計画区域内にある建築物の敷地は、原則として、建築基準法に規定する道路に2ｍ以上接していなければならない。(2023.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 144,
        "shikenId": 3,
        "no": 24,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "贈与により不動産を取得した場合、不動産取得税は課されない。(2021.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 145,
        "shikenId": 3,
        "no": 25,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "「居住用財産を譲渡した場合の3,000万円の特別控除」は、自己が居住していた家屋を配偶者や子に譲渡した場合には、適用を受けることができない。(2022.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 146,
        "shikenId": 3,
        "no": 26,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "個人間において著しく低い価額の対価で財産の譲渡が行われた場合、原則として、その譲渡があった時の譲受財産の時価と支払った対価との差額に相当する金額について、贈与税の課税対象となる。(2022.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 147,
        "shikenId": 3,
        "no": 27,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "共同相続人は、被相続人が遺言により相続分や遺産分割方法の指定をしていない場合、法定相続分どおりに相続財産を分割しなければならない。(2023.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 148,
        "shikenId": 3,
        "no": 28,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "自筆証書遺言書保管制度を利用して、法務局（遺言書保管所）に保管されている自筆証書遺言については、家庭裁判所による検認の手続を要しない。(2023.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 149,
        "shikenId": 3,
        "no": 29,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "相続税額の計算において、相続開始時に保険事故が発生していない生命保険契約に関する権利の価額は、原則として、相続開始時においてその契約を解約するとした場合に支払われることとなる解約返戻金の額によって評価する。(2022.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 150,
        "shikenId": 3,
        "no": 30,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "相続税額の計算上、死亡保険金の非課税金額の規定による非課税限度額は、「600万円×法定相続人の数」の算式により算出される。(2022.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 151,
        "shikenId": 3,
        "no": 31,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "一定の利率で複利運用しながら一定期間経過後に目標とする額を得るために必要な毎年の積立額を試算する際、目標とする額に乗じる係数は、（  ）である。（2022.09）",
        "choice1": "減債基金係数",
        "choice2": "年金現価係数",
        "choice3": "資本回収係数",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 152,
        "shikenId": 3,
        "no": 32,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "公的介護保険の第2号被保険者は、市町村または特別区の区域内に住所を有する（ ① ）以上（ ② ）未満の医療保険加入者である。（2020.01）",
        "choice1": "① 35歳　　② 65歳",
        "choice2": "① 40歳　　② 60歳",
        "choice3": "① 40歳　　② 65歳",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 153,
        "shikenId": 3,
        "no": 33,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "子のいない障害等級1級に該当する者に支給される障害基礎年金の額は、子のいない障害等級2級に該当する者に支給される障害基礎年金の額の（  ）に相当する額である。（2022.05）",
        "choice1": "0.75倍",
        "choice2": "1.25倍",
        "choice3": "1.75倍",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 154,
        "shikenId": 3,
        "no": 34,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "確定拠出年金の個人型年金の加入者が国民年金の第1号被保険者である場合、原則として、掛金の拠出限度額は年額（  ）である。（2023.01）",
        "choice1": "276,000円",
        "choice2": "816,000円",
        "choice3": "840,000円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 155,
        "shikenId": 3,
        "no": 35,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "住宅金融支援機構と民間金融機関が提携した住宅ローンであるフラット35（買取型）の融資額は、土地取得費を含めた住宅建設費用または住宅購入価額以内で、最高（①）であり、融資金利は（②）である。（2023.09）",
        "choice1": "① 8,000万円　　② 固定金利",
        "choice2": "① 1億円　　② 固定金利",
        "choice3": "① 1億円　　② 変動金利",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 156,
        "shikenId": 3,
        "no": 36,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "生命保険会社が（   ）を引き下げた場合、通常、その後の終身保険の新規契約の保険料は高くなる。（2022.05）",
        "choice1": "予定利率",
        "choice2": "予定死亡率",
        "choice3": "予定事業費率",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 157,
        "shikenId": 3,
        "no": 37,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "契約転換制度により、現在加入している生命保険契約を新たな契約に転換する場合、転換後契約の保険料は、（ ① ）の年齢に応じた保険料率により算出され、転換時において告知等をする必要が（ ② ）。（2022.05）",
        "choice1": "① 転換前契約の加入時　　② ない",
        "choice2": "① 転換時　　② ない",
        "choice3": "① 転換時　　② ある",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 158,
        "shikenId": 3,
        "no": 38,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "自動車損害賠償責任保険（自賠責保険）において、被害者1人当たりの保険金の支払限度額は、加害車両が1台の場合、死亡による損害については（①）、傷害による損害については（②）である。(2023.09)",
        "choice1": "① 3,000万円　　② 120万円",
        "choice2": "① 3,000万円　　② 150万円",
        "choice3": "① 4,000万円　　② 150万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 159,
        "shikenId": 3,
        "no": 39,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "飲食店において、店舗の床が清掃時の水で濡れていたことにより、来店客が足を滑らせて転倒して骨折し、入院をした。このような場合の損害賠償責任に備える損害保険としては、（   ）が適している。（2020.01）",
        "choice1": "生産物賠償責任保険",
        "choice2": "施設所有（管理）者賠償責任保険",
        "choice3": "受託者賠償責任保険",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 160,
        "shikenId": 3,
        "no": 40,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "医療保険等に付加される先進医療特約では、（   ）時点において厚生労働大臣により定められている先進医療が給付の対象となる。（2021.09）",
        "choice1": "申込日",
        "choice2": "責任開始日",
        "choice3": "療養を受けた日",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 161,
        "shikenId": 3,
        "no": 41,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "わが国の経済指標において、一定期間内に国内で生産された財やサービスの付加価値の合計額を（ ① ）といい、その統計は（ ② ）が作成し、公表している。（2022.05）",
        "choice1": "① マネーストック　　② 日本銀行",
        "choice2": "① 国内総生産（GDP）　　② 日本銀行",
        "choice3": "① 国内総生産（GDP）　　② 内閣府",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 162,
        "shikenId": 3,
        "no": 42,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "表面利率（クーポンレート）3％、残存期間2年の固定利付債券を額面100円当たり105円で購入した場合の最終利回り（年率・単利）は、（   ）である。なお、税金等は考慮しないものとし、計算結果は表示単位の小数点以下第3位を四捨五入している。（2023.09）",
        "choice1": "0.48％",
        "choice2": "0.50％",
        "choice3": "0.53％",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 163,
        "shikenId": 3,
        "no": 43,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "株式の投資指標のうち、ROEは、当期純利益を（   ）で除して求められる。（2022.09）",
        "choice1": "売上高",
        "choice2": "総資産",
        "choice3": "自己資本",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 164,
        "shikenId": 3,
        "no": 44,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "オプション取引において、特定の商品を将来の一定期日にあらかじめ決められた価格で買う権利のことを（ ① ）・オプションといい、他の条件が同じであれば、一般に、満期までの残存期間が長いほど、プレミアム（オプション料）は（ ② ）なる。(2021.09)",
        "choice1": "① コール　　② 高く",
        "choice2": "① コール　　② 低く",
        "choice3": "① プット　　② 低く",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 165,
        "shikenId": 3,
        "no": 45,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "追加型株式投資信託を基準価額1万3,000円（1万口当たり）で1万口購入した後、最初の決算時に1万口当たり400円の収益分配金が支払われ、分配落ち後の基準価額が1万2,700円（1万口当たり）となった場合、その収益分配金のうち、普通分配金は（ ① ）であり、元本払戻金（特別分配金）は（ ② ）である。（2023.05）",
        "choice1": "① 0円　　② 400円",
        "choice2": "① 100円　　② 300円",
        "choice3": "① 300円　　② 100円",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 166,
        "shikenId": 3,
        "no": 46,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "所得税において、為替予約を締結していない外貨定期預金の満期による為替差益は、（   ）として総合課税の対象となる。（2021.09）",
        "choice1": "利子所得",
        "choice2": "一時所得",
        "choice3": "雑所得",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 167,
        "shikenId": 3,
        "no": 47,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "所得税において、ふるさと納税の謝礼として地方公共団体から受ける返礼品に係る経済的利益は、（ 　）として総合課税の対象となる。(2023.09)",
        "choice1": "一時所得",
        "choice2": "配当所得",
        "choice3": "雑所得",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 168,
        "shikenId": 3,
        "no": 48,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "所得税において、控除対象扶養親族のうち、その年の12月31日時点の年齢が（ ① ）以上（ ② ）未満である者は、特定扶養親族に該当する。（2023.09）",
        "choice1": "① 16歳　　② 19歳",
        "choice2": "① 18歳　　② 22歳",
        "choice3": "① 19歳　　② 23歳",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 169,
        "shikenId": 3,
        "no": 49,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "青色申告者の所得税の計算において、損益通算してもなお控除しきれない損失の金額（純損失の金額）が生じた場合、所定の要件を満たすことで、その損失の金額を翌年以後（　　）にわたって繰り越して、各年分の所得金額から控除することができる。（2020.01）",
        "choice1": "2年間",
        "choice2": "3年間",
        "choice3": "4年間",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 170,
        "shikenId": 3,
        "no": 50,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "所得税において、上場株式の配当について配当控除の適用を受けるためには、その配当所得について（   ）を選択する必要がある。（2023.01）",
        "choice1": "総合課税",
        "choice2": "申告分離課税",
        "choice3": "確定申告不要制度",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 171,
        "shikenId": 3,
        "no": 51,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "借地借家法によれば、定期建物賃貸借契約（定期借家契約）の賃貸借期間が1年以上である場合、賃貸人は、原則として、期間満了の1年前から（   ）前までの間に、賃借人に対して期間満了により契約が終了する旨の通知をしなければ、その終了を賃借人に対抗することができない。（2023.05）",
        "choice1": "1カ月",
        "choice2": "3カ月",
        "choice3": "6カ月",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 172,
        "shikenId": 3,
        "no": 52,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "建築基準法によれば、第一種低層住居専用地域内の建築物の高さは、原則として（   ）のうち当該地域に関する都市計画において定められた建築物の高さの限度を超えてはならないとされている。（2023.05）",
        "choice1": "10ｍまたは12ｍ",
        "choice2": "10ｍまたは20ｍ",
        "choice3": "12ｍまたは15ｍ",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 173,
        "shikenId": 3,
        "no": 53,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "所得税の計算において、個人が土地を譲渡したことによる譲渡所得が長期譲渡所得に区分されるためには、土地を譲渡した年の1月1日における所有期間が（   ）を超えていなければならない。（2023.01）",
        "choice1": "5年",
        "choice2": "10年",
        "choice3": "20年",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 174,
        "shikenId": 3,
        "no": 54,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "固定資産税における小規模住宅用地（住宅用地で住宅1戸につき200㎡以下の部分）の課税標準については、当該住宅用地に係る固定資産税の課税標準となるべき価格の（   ）の額とする特例がある。(2022.09)",
        "choice1": "2分の1",
        "choice2": "4分の1",
        "choice3": "6分の1",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 175,
        "shikenId": 3,
        "no": 55,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "投資総額5,000万円で購入した賃貸用不動産の年間収入の合計額が270万円、年間費用の合計額が110万円である場合、この投資の純利回り（NOI利回り）は、（   ）である。（2023.09）",
        "choice1": "2.2％",
        "choice2": "3.2％",
        "choice3": "5.4％",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 176,
        "shikenId": 3,
        "no": 56,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "贈与税の配偶者控除は、婚姻期間が（ ① ）以上である配偶者から居住用不動産の贈与または居住用不動産を取得するための金銭の贈与を受け、所定の要件を満たす場合、贈与税の課税価格から基礎控除額のほかに最高（ ② ）を控除することができる特例である。（2023.01）",
        "choice1": "① 10年　　② 2,500万円",
        "choice2": "① 20年　　② 2,500万円",
        "choice3": "① 20年　　② 2,000万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 177,
        "shikenId": 3,
        "no": 57,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "相続人が相続の放棄をするには、原則として、自己のために相続の開始があったことを知った時から（ ① ）以内に、（ ② ）にその旨を申述しなければならない。（2022.05）",
        "choice1": "① 3カ月　　② 家庭裁判所",
        "choice2": "① 3カ月　　② 所轄税務署長",
        "choice3": "① 6カ月　　② 所轄税務署長",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 178,
        "shikenId": 3,
        "no": 58,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "下記の＜親族関係図＞において、Aさんの相続における父Cさんの法定相続分は、（   ）である。（2022.05）<br><img src='/img/3_1.jpg' style='width: 450px;'/>",
        "choice1": "2分の1",
        "choice2": "3分の1",
        "choice3": "4分の1",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 179,
        "shikenId": 3,
        "no": 59,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "貸家建付地の相続税評価額は、（   ）の算式により算出される。（2023.09）",
        "choice1": "自用地としての価額×(1－借地権割合)",
        "choice2": "自用地としての価額×(1－借家権割合×賃貸割合)",
        "choice3": "自用地としての価額×(1－借地権割合×借家権割合×賃貸割合)",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 180,
        "shikenId": 3,
        "no": 60,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "相続人が相続により取得した宅地が「小規模宅地等についての相続税の課税価格の計算の特例」における特定事業用宅地等に該当する場合、その宅地のうち（ ① ）までを限度面積として、評価額の（ ② ）相当額を減額した金額を、相続税の課税価格に算入すべき価額とすることができる。（2023.05）",
        "choice1": "① 200㎡　　② 50％",
        "choice2": "① 330㎡　　② 80％",
        "choice3": "① 400㎡　　② 80％",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 181,
        "shikenId": 5,
        "no": 1,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/181_1.png'/>",
        "question2": "はじめに、Ｍさんは、Ａさんが老齢基礎年金の受給を65歳から開始した場合の年金額を試算した。Ｍさんが試算した老齢基礎年金の年金額の計算式として、次のうち最も適切なものはどれか。なお、老齢基礎年金の年金額は、2024年度価額に基づいて計算するものとする。 ",
        "choice1": "<img src='/img/181_2.png' style=' height: 45px;margin-top: -10px;'/>",
        "choice2": "<img src='/img/181_3.png' style=' height: 41px;margin-top: -10px;margin-left:2px'/>",
        "choice3": "<img src='/img/181_4.png' style=' height: 42px;margin-top: -11px;'/>",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "ライフ",
        "rontenName": "老齢基礎年金の年金額",
        "important": ""
    },
    {
        "id": 182,
        "shikenId": 5,
        "no": 2,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/181_1.png'/>",
        "question2": "次に、Ｍさんは、老齢厚生年金について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「Ａさんおよび妻Ｂさんには、特別支給の老齢厚生年金は支給されません。原則として、65歳から老齢厚生年金を受給することになります」",
        "choice2": "「妻Ｂさんは厚生年金保険の被保険者期間が10年以上ありますので、Ａさんが65歳から受給する老齢厚生年金の額には、配偶者の加給年金額は加算されません」",
        "choice3": "「Ａさんが70歳０カ月で老齢厚生年金の繰下げ支給の申出をする場合、当該年金額の増額率は42％になります」",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "ライフ",
        "rontenName": "老齢厚生年金",
        "important": ""
    },
    {
        "id": 183,
        "shikenId": 5,
        "no": 3,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/181_1.png'/>",
        "question2": "最後に、Ｍさんは、老後の年金収入を増やす方法として確定拠出年金の個人型年金について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。 <br><img src='/img/181_5.png' style='width:100%'/> ",
        "choice1": "① 144,000　　② 小規模企業共済等掛金控除　　③ 10",
        "choice2": "① 144,000　　② 社会保険料控除　　③５",
        "choice3": "① 276,000　　② 小規模企業共済等掛金控除　　③ ５",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "ライフ",
        "rontenName": "個人型確定拠出年金",
        "important": ""
    },
    {
        "id": 184,
        "shikenId": 5,
        "no": 4,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/184_1.png'/>",
        "question2": "はじめに、Ｍさんは、Ｘ社株式の投資指標について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。 ",
        "choice1": "「株価の相対的な割高・割安を判断する指標として、ＰＥＲがあります。＜Ｘ社に関する資料＞から算出されるＸ社株式のＰＥＲは、12.5倍です」 ",
        "choice2": "「株主への利益還元の度合いを測る指標として、配当性向があります。＜Ｘ社に関する資料＞から算出されるＸ社の配当性向は、3.0％です」 ",
        "choice3": "「＜Ｘ社に関する資料＞から算出されるＰＥＲやＰＢＲ等の投資指標の数値は、同業他社の数値やＸ社の過去の傾向などと比較して、投資判断材料の１つとすることをお勧めします」 ",
        "choice4": "",
        "choice5": "2022.01改",
        "categoryName": "金融",
        "rontenName": "投資指標",
        "important": "必要保障額の計算"
    },
    {
        "id": 185,
        "shikenId": 5,
        "no": 5,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/184_1.png'/>",
        "question2": "次に、Ｍさんは、Ｘ社株式を購入する場合の留意点等について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。 ",
        "choice1": "「 Ａさんが特定口座（源泉徴収あり）でＸ社株式を株価2,000円で100株購入し、同年中に株価2,400円で全株売却した場合、その他の取引や手数料等を考慮しなければ、売買益４万円に対して20.315％相当額が源泉徴収等されます」 ",
        "choice2": "「 Ｘ社株式を購入する場合、配当金や売買益等が非課税となる一般ＮＩＳＡを利用することが考えられます。2022年中に一般ＮＩＳＡ勘定に新規で受け入れることができる限度額（非課税投資枠）は120万円です」",
        "choice3": "「一般ＮＩＳＡ勘定に、2022年中に新規で受け入れることができる限度額（非課税投資枠）のうち、未使用分については、2023年以降に繰り越して使用することができます」 ",
        "choice4": "",
        "choice5": "2022.01改",
        "categoryName": "金融",
        "rontenName": "株式購入、NISA",
        "important": ""
    },
    {
        "id": 186,
        "shikenId": 5,
        "no": 6,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/184_1.png'/>",
        "question2": "最後に、Ｍさんは、上場不動産投資信託（J-REIT）について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。 ",
        "choice1": "「上場不動産投資信託（J-REIT）は、投資家から集めた資金を不動産等に投資し、その賃貸収入や売買益を投資家に分配する投資信託です」 ",
        "choice2": "「上場不動産投資信託（J-REIT）は、一般ＮＩＳＡを利用して購入することができないため、一般口座や特定口座で購入する必要があります」 ",
        "choice3": "「上場不動産投資信託（J-REIT）の分配金は、不動産所得として課税の対象となります」 ",
        "choice4": "",
        "choice5": "2022.01改",
        "categoryName": "金融",
        "rontenName": "J-REIT",
        "important": ""
    },
    {
        "id": 187,
        "shikenId": 5,
        "no": 7,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/187_1.png'/>",
        "question2": "Ａさんの2024年分の所得税の確定申告に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「所得税の確定申告書は、原則として、2025年２月16日から３月31日までの間に、Ａさんの住所地を所轄する税務署長に提出してください」",
        "choice2": "「Ａさんは、不動産所得の金額が20万円を超えていますので、所得税の確定申告をしなければなりません」",
        "choice3": "「Ａさんは、所得税の確定申告をすることにより、外貨定期預金の為替差損の金額を、不動産所得の金額と損益通算することができます」",
        "choice4": "",
        "choice5": "2023.01改",
        "categoryName": "タックス",
        "rontenName": "所得税の確定申告",
        "important": ""
    },
    {
        "id": 188,
        "shikenId": 5,
        "no": 8,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/187_1.png'/>",
        "question2": "Ａさんの2024年分の所得税における総所得金額は、次のうちどれか。<br><img src='/img/187_2.png' style='width:400px'/>",
        "choice1": "653万円",
        "choice2": "658万円",
        "choice3": "663万円",
        "choice4": "",
        "choice5": "2023.01改",
        "categoryName": "タックス",
        "rontenName": "総所得金額の計算",
        "important": ""
    },
    {
        "id": 189,
        "shikenId": 5,
        "no": 9,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/187_1.png'/>",
        "question2": "Ａさんの2024年分の所得税における所得控除に関する以下の文章の空欄①～③に入る数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/187_3.png' style='width:100%'/>",
        "choice1": "①38　　②48　　③38",
        "choice2": "①48　　②48　　③76",
        "choice3": "①48　　②38　　③101",
        "choice4": "",
        "choice5": "2023.01改",
        "categoryName": "タックス",
        "rontenName": "所得控除",
        "important": ""
    },
    {
        "id": 190,
        "shikenId": 5,
        "no": 10,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/190_1.png'/>",
        "question2": "甲土地に耐火建築物を建築する場合の①建蔽率の上限となる建築面積と②容積率の上限となる延べ面積の組合せとして、次のうち最も適切なものはどれか。",
        "choice1": "①360㎡　　②1,440㎡",
        "choice2": "①360㎡　　②1,200㎡",
        "choice3": "①400㎡　　②1,200㎡",
        "choice4": "",
        "choice5": "2021.09",
        "categoryName": "不動産",
        "rontenName": "建蔽率と容積率の計算",
        "important": ""
    },
    {
        "id": 191,
        "shikenId": 5,
        "no": 11,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/190_1.png'/>",
        "question2": "自宅（建物およびその敷地である甲土地）の譲渡に関する以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/190_2.png' style='width:100%'/>",
        "choice1": "①３年　　②１億円　　③５年",
        "choice2": "①５年　　②１億円　　③10年",
        "choice3": "①３年　　②6,000万円　　③10年",
        "choice4": "",
        "choice5": "2021.09",
        "categoryName": "不動産",
        "rontenName": "不動産の譲渡所得の特例",
        "important": ""
    },
    {
        "id": 192,
        "shikenId": 5,
        "no": 12,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/190_1.png'/>",
        "question2": "自己建設方式による甲土地の有効活用に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「Ａさんが甲土地に賃貸マンションを建築した場合、相続税の課税価格の計算上、甲土地は貸宅地として評価されます」",
        "choice2": "「甲土地が貸付事業用宅地等に該当すれば、『小規模宅地等についての相続税の課税価格の計算の特例』の適用を受けることができます。貸付事業用宅地等は、相続税の課税価格の計算上、200㎡までの部分について50％の減額が受けられます」",
        "choice3": "「賃貸マンションを建築することで相続税等の軽減が期待できますが、将来の賃料収入が減少することや、借入金の返済が滞ることなどのリスクを考慮し、実行にあたっては慎重な計画が求められます」",
        "choice4": "",
        "choice5": "2021.09",
        "categoryName": "不動産",
        "rontenName": "自己建設方式",
        "important": ""
    },
    {
        "id": 193,
        "shikenId": 5,
        "no": 13,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/193_1.png'/>",
        "question2": "遺言に関する次の記述のうち、最も適切なものはどれか。 ",
        "choice1": "「公正証書遺言は、証人２人以上の立会いのもと、遺言者が遺言の趣旨を公証人に口授し、公証人がこれを筆記して作成するものです」",
        "choice2": "「自筆証書遺言は、所定の手続により法務局（遺言書保管所）に保管することができますが、法務局に保管された自筆証書遺言は、相続開始時に家庭裁判所による検認手続が必要となります」",
        "choice3": "「Ａさんの遺言による相続分の指定や遺贈によって相続人の遺留分が侵害された場合、その遺言は無効となります」",
        "choice4": "",
        "choice5": "2023.09改",
        "categoryName": "相続",
        "rontenName": "遺言",
        "important": ""
    },
    {
        "id": 194,
        "shikenId": 5,
        "no": 14,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/193_1.png'/>",
        "question2": "仮に、Ａさんの相続が現時点（2024年９月10日）で開始し、Ａさんの相続に係る課税遺産総額（課税価格の合計額－遺産に係る基礎控除額）が２億1,000万円であった場合の相続税の総額は、次のうちどれか。<br><img src='/img/193_2.png' style='width:400px'/>",
        "choice1": "3,500万円",
        "choice2": "4,250万円",
        "choice3": "6,750万円",
        "choice4": "",
        "choice5": "2023.09改",
        "categoryName": "相続",
        "rontenName": "相続税額の計算",
        "important": ""
    },
    {
        "id": 195,
        "shikenId": 5,
        "no": 15,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/193_1.png'/>",
        "question2": "現時点（2024年９月10日）において、Ａさんの相続が開始した場合に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「Ａさんの相続における相続税額の計算上、遺産に係る基礎控除額は、4,500万円となります」",
        "choice2": "「自宅の敷地と賃貸マンションの敷地について、『小規模宅地等についての相続税の課税価格の計算の特例』の適用を受けようとする場合、適用対象面積は所定の算式により調整され、完全併用はできません」",
        "choice3": "「孫Ｅさんが遺贈により財産を取得した場合、相続税額の２割加算の対象となります」",
        "choice4": "",
        "choice5": "2023.09改",
        "categoryName": "相続",
        "rontenName": "相続に関する記述",
        "important": ""
    },
    {
        "id": 196,
        "shikenId": 6,
        "no": 1,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/196_1.png'/>",
        "question2": "はじめに、Ｍさんは、《設例》の＜Ａさんとその家族に関する資料＞に基づき、Ａさんが老齢基礎年金の受給を65歳から開始した場合の年金額（2024年度価額）を試算した。Ｍさんが試算した老齢基礎年金の年金額の計算式として、次のうち最も適切なものはどれか。",
        "choice1": "<img src='/img/196_2.png' style=' height: 41px;margin-top: -11px;'/>",
        "choice2": "<img src='/img/196_3.png' style=' height: 41px;margin-top: -12px;'/>",
        "choice3": "<img src='/img/196_4.png' style=' height: 39px;margin-top: -10px;'/>",
        "choice4": "",
        "choice5": "2023.09改",
        "categoryName": "ライフ",
        "rontenName": "老齢基礎年金の年金額",
        "important": ""
    },
    {
        "id": 197,
        "shikenId": 6,
        "no": 2,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/196_1.png'/>",
        "question2": "次に、Ｍさんは、老齢基礎年金の繰上げ支給および繰下げ支給について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/196_5.png' style='width:100%'/> ",
        "choice1": "①生涯　　②24％　　③75歳",
        "choice2": "①80歳まで　　②30％　　③75歳",
        "choice3": "①生涯　　②30％　　③70歳",
        "choice4": "",
        "choice5": "2023.09改",
        "categoryName": "ライフ",
        "rontenName": "繰上げ受給と繰下げ受給",
        "important": ""
    },
    {
        "id": 198,
        "shikenId": 6,
        "no": 3,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/196_1.png'/>",
        "question2": "最後に、Ｍさんは、公的年金制度からの老齢給付について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「Ａさんおよび妻Ｂさんには、特別支給の老齢厚生年金は支給されません。原則として、65歳から老齢厚生年金を受給することになります」",
        "choice2": "「Ａさんが老齢基礎年金の繰上げ支給の請求をする場合、その請求と同時に老齢厚生年金の繰上げ支給の請求をしなければなりません」",
        "choice3": "「Ａさんが65歳から受給することができる老齢厚生年金の額には、配偶者の加給年金額が加算されます」",
        "choice4": "",
        "choice5": "2023.09改",
        "categoryName": "ライフ",
        "rontenName": "老齢給付",
        "important": ""
    },
    {
        "id": 199,
        "shikenId": 6,
        "no": 4,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/199_1.png'/>",
        "question2": "はじめに、Ｍさんは、生命保険の加入等についてアドバイスした。ＭさんのＡさんに対するアドバイスとして、次のうち最も適切なものはどれか。",
        "choice1": "「⽣命保険に加⼊する際には、Aさんの傷病歴や現在の健康状態などについて、事実をありのままに正しく告知する必要があります。なお、告知受領権は⽣命保険募集⼈が有していますので、当該募集⼈に対して、⼝頭で告知してください」",
        "choice2": "「提案を受けた終⾝医療保険の保険料払込期間を有期払込にすることで、毎⽉の保険料負担は減少し、保険料の払込総額も少なくなります。⽉々の保険料負担を軽減するために有期払込を選択することをお勧めします」",
        "choice3": "「⽣命保険は、契約にあたって保険会社指定の医師による診査を受けた場合などを除き、保険業法に定める保険契約の申込みの撤回等（クーリング・オフ）の対象となり、所定の期間内であれば、書⾯または電磁的記録により申込みの撤回等をすることができます」",
        "choice4": "",
        "choice5": "2021.01",
        "categoryName": "リスク（個人）",
        "rontenName": "生命保険の加入等",
        "important": "必要保障額の計算"
    },
    {
        "id": 200,
        "shikenId": 6,
        "no": 5,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/199_1.png'/>",
        "question2": "次にＭさんは、Ａさんが提案を受けた終身医療保険について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「先進医療の治療を受けた場合、診察料や投薬料等に係る費⽤は公的医療保険の対象となりますが、技術料に係る費⽤は全額⾃⼰負担となりますので、先進医療特約の付加をご検討ください」",
        "choice2": "「Aさんが、がんに罹患した場合、がん⼀時⾦特約から100万円を受け取ることができます。ただし、通常、がんの保障については契約⽇から６カ⽉間の免責期間があります」",
        "choice3": "「Aさんが、がんと診断確定され、がん⼀時⾦特約から⼀時⾦を受け取った場合、当該⼀時⾦は⼀時所得として総合課税の対象となります」",
        "choice4": "",
        "choice5": "2021.01",
        "categoryName": "リスク（個人）",
        "rontenName": "終身医療保険",
        "important": ""
    },
    {
        "id": 201,
        "shikenId": 6,
        "no": 6,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 4,
        "question1": "<img src='/img/199_1.png'/>",
        "question2": "最後に、Ｍさんは、全国健康保険協会管掌健康保険の高額療養費制度について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/199_2.png' style='width:100%'/> ",
        "choice1": "①１　　②同⼀⽉　　③12,000",
        "choice2": "①３　　②同⼀年　　③12,000",
        "choice3": "①３　　②同⼀⽉　　③21,000",
        "choice4": "",
        "choice5": "2021.01",
        "categoryName": "リスク（個人）",
        "rontenName": "高額療養費制度",
        "important": ""
    },
    {
        "id": 202,
        "shikenId": 6,
        "no": 7,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/202_1.png'/>",
        "question2": "仮に、将来Ｘ社がＡさんに役員退職金5,000万円を支給した場合、Ａさんが受け取る役員退職金に係る退職所得の金額として、次のうち最も適切なものはどれか。なお、Ａさんの役員在任期間（勤続年数）を40年とし、これ以外に退職手当等の収入はなく、障害者になったことが退職の直接の原因ではないものとする。",
        "choice1": "1,400万円",
        "choice2": "2,200万円",
        "choice3": "2,800万円",
        "choice4": "",
        "choice5": "2021.05",
        "categoryName": "リスク（法人）",
        "rontenName": "退職所得の計算",
        "important": ""
    },
    {
        "id": 203,
        "shikenId": 6,
        "no": 8,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/202_1.png'/>",
        "question2": "《設例》の終身保険の第１回保険料払込時の経理処理（仕訳）として、次のうち最も適切なものはどれか。",
        "choice1": "<img src='/img/202_2.png' style=' height: 85px;margin-top: -5px;'/>",
        "choice2": "<img src='/img/202_3.png' style=' height: 85px;margin-top: -5px;'/>",
        "choice3": "<img src='/img/202_4.png' style=' height: 85px;margin-top: -5px;'/>",
        "choice4": "",
        "choice5": "2021.05",
        "categoryName": "リスク（法人）",
        "rontenName": "終身保険の経理処理",
        "important": ""
    },
    {
        "id": 204,
        "shikenId": 6,
        "no": 9,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/202_1.png'/>",
        "question2": "Ｍさんは《設例》の終身保険について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「Ａさんが所定の高度障害状態になった場合、高度障害保険金5,000万円がＸ社に支払われます。さらに、その後Ａさんが死亡した場合には、死亡保険金5,000万円がＸ社に支払われます」",
        "choice2": "「急な資金需要の発生により、Ｘ社が契約者貸付制度を利用した場合、当該終身保険契約は継続しているため、経理処理は必要ありません」",
        "choice3": "「Ａさんの勇退時に、役員退職金の一部として当該終身保険の契約者をＡさん、死亡保険金受取人をＡさんの相続人に名義変更し、当該終身保険をＡさんの個人の保険として継続することが可能です」",
        "choice4": "",
        "choice5": "2021.05",
        "categoryName": "リスク（法人）",
        "rontenName": "終身保険の説明",
        "important": ""
    },
    {
        "id": 205,
        "shikenId": 6,
        "no": 10,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/205_1.png'/>",
        "question2": "Ａさんの2024年分の所得税における所得控除に関する以下の文章の空欄①～③に入る数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/205_2.png' style='width:100%'/> ",
        "choice1": "①26　　②63　　③48",
        "choice2": "①38　　②63　　③58",
        "choice3": "①38　　②58　　③48",
        "choice4": "",
        "choice5": "2022.01改",
        "categoryName": "タックス",
        "rontenName": "所得控除",
        "important": ""
    },
    {
        "id": 206,
        "shikenId": 6,
        "no": 11,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/205_1.png'/>",
        "question2": "Ａさんの2024年分の所得税の課税に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「Ａさんが受け取った一時払変額個人年金保険の解約返戻金は、源泉分離課税の対象となります」",
        "choice2": "「2024年中に解約した一時払変額個人年金保険の保険差益が20万円を超えるため、Ａさんは所得税の確定申告をしなければなりません」",
        "choice3": "「Ａさんが支払っている長女Ｃさんの国民年金の保険料は、その全額が社会保険料控除の対象となります」",
        "choice4": "",
        "choice5": "2022.01改",
        "categoryName": "タックス",
        "rontenName": "所得税の課税に関する記述",
        "important": ""
    },
    {
        "id": 207,
        "shikenId": 6,
        "no": 12,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/205_1.png'/>",
        "question2": "Ａさんの2024年分の所得税における総所得金額は、次のうちどれか。<br><img src='/img/205_3.png' style='width:100%'/> ",
        "choice1": "565万円",
        "choice2": "590万円",
        "choice3": "615万",
        "choice4": "",
        "choice5": "2022.01改",
        "categoryName": "タックス",
        "rontenName": "総所得金額の計算",
        "important": ""
    },
    {
        "id": 208,
        "shikenId": 6,
        "no": 13,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/208_1.png'/>",
        "question2": "Ａさんの相続に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「Ａさんが2024年分の所得税について確定申告書を提出しなければならない者に該当する場合、相続人は、原則として、相続の開始があったことを知った日の翌日から３カ月以内に準確定申告書を提出しなければなりません」",
        "choice2": "「Ａさんの自宅から自筆証書遺言を発見した相続人は、相続の開始を知った後、遅滞なく、その遺言書を家庭裁判所に提出し、その検認を請求しなければなりません」",
        "choice3": "「相続税の申告書は、原則として、相続の開始があったことを知った日の翌日から10カ月以内に、被相続人であるＡさんの死亡時の住所地を所轄する税務署長に提出しなければなりません」",
        "choice4": "",
        "choice5": "2023.01改",
        "categoryName": "相続",
        "rontenName": "相続に関する基礎知識",
        "important": ""
    },
    {
        "id": 209,
        "shikenId": 6,
        "no": 14,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/208_1.png'/>",
        "question2": "Ａさんの相続に関する以下の文章の空欄①～③に入る数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/208_2.png' style='width:100%'/> ",
        "choice1": "①4,800　　②4,000　　③400",
        "choice2": "①4,200　　②4,000　　③330",
        "choice3": "①4,800　　②3,500　　③330",
        "choice4": "",
        "choice5": "2023.01改",
        "categoryName": "相続",
        "rontenName": "相続に関する数値",
        "important": ""
    },
    {
        "id": 210,
        "shikenId": 6,
        "no": 15,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/208_1.png'/>",
        "question2": "Ａさんの相続に係る課税遺産総額（課税価格の合計額－遺産に係る基礎控除額）が２億2,000万円であった場合の相続税の総額は、次のうちどれか。<br><img src='/img/208_3.png' style='width:100%'/> ",
        "choice1": "4,600万円",
        "choice2": "5,400万円",
        "choice3": "7,200万円",
        "choice4": "",
        "choice5": "2023.01改",
        "categoryName": "相続",
        "rontenName": "相続税額の計算",
        "important": ""
    },
    {
        "id": 211,
        "shikenId": 4,
        "no": 1,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "ファイナンシャル・プランニング業務を行うに当たっては、関連業法を順守することが重要である。ファイナンシャル・プランナー（以下「ＦＰ」という）の行為に関する次の記述のうち、最も適切なものはどれか。",
        "question2": "",
        "choice1": "投資助言・代理業の登録をしていないＦＰが、顧客と投資顧問契約を締結し、当該契約に基づいて特定の上場株式の投資判断について助言をした。",
        "choice2": "税理士資格を有していないＦＰが、参加費無料の相談会において、相談者の持参した資料に基づき、相談者が納付すべき相続税の具体的な税額計算を行った。",
        "choice3": "生命保険募集人・保険仲立人の登録を受けていないＦＰが、変額年金保険の一般的な商品内容について説明を行った。",
        "choice4": "",
        "choice5": "2021.05",
        "categoryName": "ライフ",
        "rontenName": "FPと関連業法",
        "important": ""
    },
    {
        "id": 212,
        "shikenId": 4,
        "no": 2,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "下記は、平尾家のキャッシュフロー表（一部抜粋）である。このキャッシュフロー表の空欄（ア）、（イ）にあてはまる数値の組み合わせとして、正しいものはどれか。なお、計算過程においては端数処理をせず計算し、計算結果については万円未満を四捨五入すること。<br><img src='/img/212.png'/><br>※年齢および金融資産残高は各年１２月３１日現在のものとし、２０２４年を基準年とする。<br>※給与収入は可処分所得で記載している。<br>※記載されている数値は正しいものとする。<br>※問題作成の都合上、一部を空欄にしてある。",
        "question2": "",
        "choice1": "（ア）５３１　　（イ）７８８",
        "choice2": "（ア）５３２　　（イ）７９６",
        "choice3": "（ア）５３２　　（イ）７９５",
        "choice4": "",
        "choice5": "2023.01改",
        "categoryName": "ライフ",
        "rontenName": "キャッシュフロー表",
        "important": ""
    },
    {
        "id": 213,
        "shikenId": 4,
        "no": 3,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "下記＜資料＞の投資信託を５０万口購入する場合の購入金額として、正しいものはどれか。なお、解答に当たっては、円未満を切り捨てること。<br><img src='/img/213.png' style='width:400px'/>",
        "question2": "",
        "choice1": "９９９,３０２円",
        "choice2": "１,０１４,５９０円",
        "choice3": "１,０２１,１４２円",
        "choice4": "",
        "choice5": "2023.01",
        "categoryName": "金融",
        "rontenName": "投資信託購入金額計算",
        "important": ""
    },
    {
        "id": 214,
        "shikenId": 4,
        "no": 4,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "広尾さんは、預金保険制度の対象となるＨＡ銀行の国内支店に下記＜資料＞の金融資産を預け入れている。仮に、ＨＡ銀行が破綻した場合、預金保険制度によって保護される金額として、正しいものはどれか。<br><img src='/img/214.png' style='width:550px'/>",
        "question2": "",
        "choice1": "５８０万円",
        "choice2": "７００万円",
        "choice3": "８６０万円",
        "choice4": "",
        "choice5": "2022.09",
        "categoryName": "金融",
        "rontenName": "預金保険制度",
        "important": "必要保障額の計算"
    },
    {
        "id": 215,
        "shikenId": 4,
        "no": 5,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "下記＜資料＞に基づくＲＶ株式会社の投資指標に関する次の記述のうち、最も適切なものはどれか。なお、購入時の手数料および税金は考慮しないこととし、計算結果については表示単位の小数点以下第３位を四捨五入すること。<br><img src='/img/215.png' style='width:450px'/>",
        "question2": "",
        "choice1": "株価純資産倍率（ＰＢＲ）は、１.１倍である。",
        "choice2": "配当利回りは、１.３６％である。",
        "choice3": "配当性向は、１０％である。",
        "choice4": "",
        "choice5": "2021.09",
        "categoryName": "金融",
        "rontenName": "投資指標",
        "important": ""
    },
    {
        "id": 216,
        "shikenId": 4,
        "no": 6,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "下記＜資料＞の甲土地の建築面積の最高限度を算出する基礎となる敷地面積として、正しいものはどれか。なお、この土地の存する区域は、特定行政庁が指定する区域に該当しないものとし、その他記載のない条件については一切考慮しないこととする。<br><img src='/img/216.png'/>",
        "question2": "",
        "choice1": "２６０㎡",
        "choice2": "２８０㎡",
        "choice3": "２９０㎡",
        "choice4": "",
        "choice5": "2023.05",
        "categoryName": "不動産",
        "rontenName": "敷地面積（セットバック）",
        "important": ""
    },
    {
        "id": 217,
        "shikenId": 4,
        "no": 7,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "借地借家法に基づく普通借家権に関する以下の記述の空欄（ア）～（ウ）に入る語句の組み合わせとして、最も適切なものはどれか。<br><img src='/img/217.png' style='width:600px'/>",
        "question2": "",
        "choice1": "（ア）１年 （イ）３ヵ月 （ウ）１ヵ月",
        "choice2": "（ア）１年 （イ）６ヵ月 （ウ）３ヵ月",
        "choice3": "（ア）２年 （イ）６ヵ月 （ウ）３ヵ月",
        "choice4": "",
        "choice5": "2023.01",
        "categoryName": "不動産",
        "rontenName": "普通借家権",
        "important": ""
    },
    {
        "id": 218,
        "shikenId": 4,
        "no": 8,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "明石誠二さんが加入しているがん保険（下記＜資料＞参照）の保障内容に関する次の記述の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、保険契約は有効に継続しているものとし、誠二さんはこれまでに＜資料＞の保険から保険金および給付金を一度も受け取っていないものとする。<br><img src='/img/218.png'/>",
        "question2": "",
        "choice1": "１,６２０,０００円",
        "choice2": "２,７２０,０００円",
        "choice3": "３,６２０,０００円",
        "choice4": "",
        "choice5": "2023.05",
        "categoryName": "リスク",
        "rontenName": "生命保険証券の見方",
        "important": ""
    },
    {
        "id": 219,
        "shikenId": 4,
        "no": 9,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "会社員で共働きの大場さん夫妻が加入している生命保険は下表のとおりである。下表の契約Ａ～Ｃについて、保険金が支払われた場合の課税関係に関する次の記述のうち、最も適切なものはどれか。<br><img src='/img/219.png'/>",
        "question2": "",
        "choice1": "契約Ａについて、子が受け取った死亡保険金は贈与税の課税対象となる。",
        "choice2": "契約Ｂについて、妻が受け取った死亡保険金は相続税の課税対象となる。",
        "choice3": "契約Ｃについて、妻が受け取った死亡保険金は所得税・住民税の課税対象となる。",
        "choice4": "",
        "choice5": "2022.01",
        "categoryName": "リスク",
        "rontenName": "生命保険金の課税関係",
        "important": ""
    },
    {
        "id": 220,
        "shikenId": 4,
        "no": 10,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "損害保険の⽤語に関する次の記述のうち、最も不適切なものはどれか。",
        "question2": "",
        "choice1": "再調達価額とは、保険の対象と同等のものを新たに建築または購⼊するのに必要な⾦額のことである。",
        "choice2": "保険料率とは、契約者から⽀払われた保険料の総額に対する保険会社が⽀払った保険⾦の総額の割合のことである。",
        "choice3": "⼀部保険とは、保険⾦額が保険の対象の価額（保険価額）に満たない保険のことである。",
        "choice4": "",
        "choice5": "2020.09",
        "categoryName": "リスク",
        "rontenName": "損害保険の用語",
        "important": ""
    },
    {
        "id": 221,
        "shikenId": 4,
        "no": 11,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "下記＜資料＞に基づき、目黒昭雄さんの２０２３年分の所得税を計算する際の所得控除に関する次の記述のうち、最も適切なものはどれか。<br><img src='/img/221.png' style='width: 550px'/>",
        "question2": "",
        "choice1": "妻の聡美さんは控除対象配偶者となり、昭雄さんは３８万円を控除することができる。",
        "choice2": "長男の幸一さんは特定扶養親族となり、昭雄さんは６３万円を控除することができる。",
        "choice3": "二男の浩二さんは一般の扶養親族となり、昭雄さんは３８万円を控除することができる。",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "タックス",
        "rontenName": "所得控除",
        "important": ""
    },
    {
        "id": 222,
        "shikenId": 4,
        "no": 12,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "会社員の飯田さんは、２０２３年中に勤務先を定年退職した。飯田さんの退職に係るデータが下記＜資料＞のとおりである場合、飯田さんの所得税に係る退職所得の金額として、正しいものはどれか。<br><img src='/img/222.png'/>",
        "question2": "",
        "choice1": "３６０万円",
        "choice2": "３９５万円",
        "choice3": "７２０万円",
        "choice4": "",
        "choice5": "2022.05改",
        "categoryName": "タックス",
        "rontenName": "退職所得の計算",
        "important": ""
    },
    {
        "id": 223,
        "shikenId": 4,
        "no": 13,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "２０２４年１月５日に相続が開始された牧村誠一さん（被相続人）の＜親族関係図＞が下記のとおりである場合、民法上の相続人および法定相続分の組み合わせとして、正しいものはどれか。なお、記載のない条件については一切考慮しないこととする。<br><img src='/img/223.png'/>",
        "question2": "",
        "choice1": "智子   １／２       歩美    １／２",
        "choice2": "智子   １／２       歩美    １／４       大雅   １／４",
        "choice3": "智子   １／２       歩美    １／６       大雅   １／６       莉名   １／６",
        "choice4": "",
        "choice5": "2022.01改",
        "categoryName": "相続",
        "rontenName": "法定相続分",
        "important": ""
    },
    {
        "id": 224,
        "shikenId": 4,
        "no": 14,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "村瀬高志さん（３０歳）が２０２３年中に贈与を受けた財産の価額と贈与者は以下のとおりである。高志さんの２０２３年分の贈与税額として、正しいものはどれか。なお、２０２３年中において、高志さんはこれ以外の財産の贈与を受けておらず、相続時精算課税制度は選択していないものとする。<br><img src='/img/224.png'/>",
        "question2": "",
        "choice1": "６８万円",
        "choice2": "８２万円",
        "choice3": "９０万円",
        "choice4": "",
        "choice5": "2021.09改",
        "categoryName": "相続",
        "rontenName": "贈与税額の計算",
        "important": ""
    },
    {
        "id": 225,
        "shikenId": 4,
        "no": 15,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "今年８０歳になる安西さんは、将来発生するであろう自身の相続について、遺産分割等でのトラブルを防ぐために遺言書の作成を検討しており、ＦＰの高梨さんに相談をした。遺言書に関する高梨さんの次の説明のうち、最も適切なものはどれか。",
        "question2": "",
        "choice1": "「公正証書遺言を作成した後に、自筆証書遺言によって、先に作成した公正証書遺言を撤回することができます。」",
        "choice2": "「自筆証書遺言を作成した場合、原則として、遺言書の保管者または遺言書を発見した相続人は、遅滞なく遺言書を公証役場に提出して、その検認を請求する必要があります。」",
        "choice3": "「自筆証書遺言を作成する場合、遺言者と２人以上の証人が、各自これに署名し、押印をすることが必要です。」",
        "choice4": "",
        "choice5": "2022.05",
        "categoryName": "相続",
        "rontenName": "遺言書",
        "important": ""
    },
    {
        "id": 226,
        "shikenId": 4,
        "no": 16,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "<img src='/img/226.png'><br>ＦＰの青山さんは、川野家のバランスシートを作成した。下表の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、＜設例＞に記載のあるデータに基づいて解答することとする。<br><img src='/img/226_2.png'>",
        "question2": "",
        "choice1": "３７０（万円）",
        "choice2": "４７０（万円）",
        "choice3": "４８５（万円）",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "総合",
        "rontenName": "バランスシート",
        "important": ""
    },
    {
        "id": 227,
        "shikenId": 4,
        "no": 17,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "恭平さんと亜美さんは、今後１０年間で毎年２４万円ずつ積立貯蓄をして、潤さんの教育資金を準備したいと考えている。積立期間中に年利１.０％で複利運用できるものとした場合、１０年後の積立金額として、正しいものはどれか。なお、下記＜資料＞の３つの係数の中から最も適切な係数を選択して計算し、解答に当たっては万円未満を切り捨てること。また、税金や記載のない事項については一切考慮しないこととする。<br><img src='/img/227.png' style='width:550px'>",
        "question2": "",
        "choice1": "２６５万円",
        "choice2": "２５１万円",
        "choice3": "２２７万円",
        "choice4": "",
        "choice5": "2023.05",
        "categoryName": "総合",
        "rontenName": "係数の計算",
        "important": ""
    },
    {
        "id": 228,
        "shikenId": 4,
        "no": 18,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "恭平さんは、会社の定期健康診断で異常を指摘され、２０２４年３月に３週間ほど入院をして治療を受けた。その際病院への支払いが高額であったため、恭平さんは健康保険の高額療養費制度によって払い戻しを受けたいと考え、ＦＰの青山さんに相談をした。恭平さんの２０２４年３月の保険診療に係る総医療費が８０万円であった場合、高額療養費制度により払い戻しを受けることができる金額として、正しいものはどれか。なお、恭平さんは全国健康保険協会管掌健康保険（協会けんぽ）の被保険者で、標準報酬月額は「３８万円」である。また、恭平さんは限度額適用認定証を病院に提出していないものとする。<br><img src='/img/228.png' style='width:600px'>",
        "question2": "",
        "choice1": "８５,４３０円",
        "choice2": "１５４,５７０円",
        "choice3": "７１４,５７０円",
        "choice4": "",
        "choice5": "2023.05改",
        "categoryName": "総合",
        "rontenName": "高額療養費制度",
        "important": ""
    },
    {
        "id": 229,
        "shikenId": 4,
        "no": 19,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "亜美さんは、間もなく第二子を出産予定で、出産後は子が１歳になるまで育児休業を取得しようと思っている。育児休業期間中の健康保険および厚生年金保険の保険料の免除に関する次の記述のうち、最も適切なものはどれか。なお、亜美さんは全国健康保険協会管掌健康保険（協会けんぽ）の被保険者であり、かつ厚生年金保険の被保険者である。",
        "question2": "",
        "choice1": "事業主の申出により、被保険者負担分のみ免除される。",
        "choice2": "事業主の申出により、事業主負担分のみ免除される。",
        "choice3": "事業主の申出により、被保険者および事業主負担分が免除される。",
        "choice4": "",
        "choice5": "2023.05",
        "categoryName": "総合",
        "rontenName": "育休中の保険料",
        "important": ""
    },
    {
        "id": 230,
        "shikenId": 4,
        "no": 20,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "恭平さんが保有する投資信託は、投資信託①と投資信託②であり、５年前にそれぞれ１５万円ずつ合計３０万円を購入したものである。恭平さんは「リバランス」に興味をもち、ＦＰの青山さんに質問をした。下記の空欄（ア）～（ウ）にあてはまる語句に関する次の記述のうち、最も不適切なものはどれか。なお、手数料は考慮しないものとする。<br><img src='/img/230.png'>",
        "question2": "",
        "choice1": "（ア）にあてはまる語句は、「配分比率」である。",
        "choice2": "（イ）にあてはまる語句は、「１５万円分売却」である。",
        "choice3": "（ウ）にあてはまる語句は、「１０万円分購入」である。",
        "choice4": "",
        "choice5": "2023.05",
        "categoryName": "総合",
        "rontenName": "投資信託リバランス",
        "important": ""
    },
    {
        "id": 231,
        "shikenId": 7,
        "no": 1,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "公的介護保険の第２号被保険者は、市町村または特別区の区域内に住所を有する65歳以上の者である。［2021年9月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 232,
        "shikenId": 7,
        "no": 2,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "全国健康保険協会管掌健康保険の被保険者である会社員が、退職後に任意継続被保険者となるためには、資格喪失日から14日以内に任意継続被保険者となるための申出をしなければならない。［2020年9月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 233,
        "shikenId": 7,
        "no": 3,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "老齢厚生年金の繰下げ支給の申出は、老齢基礎年金の繰下げ支給の申出と同時に行う必要はない。［2022年5月改題］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 234,
        "shikenId": 7,
        "no": 4,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "国民年金の第３号被保険者は、確定拠出年金の個人型年金の加入者となることはできない。［2019年9月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 235,
        "shikenId": 7,
        "no": 5,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "日本政策金融公庫の教育一般貸付（国の教育ローン）の使途は、入学金や授業料などの学校納付金に限られ、受験費用や在学のために必要となる住居費用などに利用することはできない。［2023年5月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 236,
        "shikenId": 7,
        "no": 6,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "延長保険とは、一般に、現在加入している生命保険の保険料の払込みを中止し、その時点での解約返戻金を基に、元契約の保険金額を変えずに一時払いの定期保険に変更する制度である。［2022年5月改題］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 237,
        "shikenId": 7,
        "no": 7,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "一時払終身保険は、早期に解約した場合であっても、解約返戻金額が一時払保険料相当額を下回ることはない。［2021年1月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 238,
        "shikenId": 7,
        "no": 8,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "自動車損害賠償責任保険（自賠責保険）では、被保険者自身が単独事故でケガをした場合、その損害は補償の対象とならない。［2022年9月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 239,
        "shikenId": 7,
        "no": 9,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "家族傷害保険の被保険者の範囲には、被保険者本人と生計を共にする別居の未婚の子も含まれる。［2020年1月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 240,
        "shikenId": 7,
        "no": 10,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税において、個人が支払う地震保険の保険料は、５万円を限度として年間支払保険料の２分の１相当額が地震保険料控除の対象となる。［2021年1月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 241,
        "shikenId": 7,
        "no": 11,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "米国の市場金利が上昇し、日本と米国の金利差が拡大することは、一般に、米ドルと円の為替相場において米ドル安、円高の要因となる。［2023年1月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 242,
        "shikenId": 7,
        "no": 12,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "インデックス型投資信託は、日経平均株価や東証株価指数（TOPIX）などの特定の指標に連動するよう運用される投資信託である。［2021年1月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 243,
        "shikenId": 7,
        "no": 13,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "元金2,500,000円を、年利４％（１年複利）で３年間運用した場合の元利合計額は、税金や手数料等を考慮しない場合、2,812,160円である。［2024年1月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 244,
        "shikenId": 7,
        "no": 14,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "配当性向とは、当期純利益に占める配当金総額の割合を示す指標である。［2022年5月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 245,
        "shikenId": 7,
        "no": 15,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "特定口座を開設している金融機関に、NISA口座（少額投資非課税制度における非課税口座）を開設した場合、特定口座内の株式投資信託をNISA口座に移管することができる。［2022年1月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 246,
        "shikenId": 7,
        "no": 16,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "所得税において、医療保険の被保険者が病気で入院したことにより受け取った入院給付金は、非課税である。［2022年1月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 247,
        "shikenId": 7,
        "no": 17,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税において、事業的規模で行われている賃貸マンションの貸付による所得は、事業所得となる。［2021年5月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 248,
        "shikenId": 7,
        "no": 18,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "所得税において、国民年金基金の掛金は、社会保険料控除の対象となる。［2023年5月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 249,
        "shikenId": 7,
        "no": 19,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "住宅ローンを利用して住宅を新築した個人が、所得税の住宅借入金等特別控除の適用を受けるためには、当該住宅を新築した日から１カ月以内に自己の居住の用に供さなければならない。［2023年1月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 250,
        "shikenId": 7,
        "no": 20,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "給与所得者のうち、その年中に支払を受けるべき給与の収入金額が1,000万円を超える者は、所得税の確定申告をしなければならない。［2020年1月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 251,
        "shikenId": 7,
        "no": 21,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "不動産登記には公信力が認められていないため、登記記録上の権利者が真実の権利者と異なっている場合に、登記記録を信じて不動産を購入した者は、原則として、その不動産に対する権利の取得について法的に保護されない。［2023年5月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 252,
        "shikenId": 7,
        "no": 22,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "借地借家法において、事業用定期借地権等の設定を目的とする契約は、公正証書によってしなければならない。［2023年1月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 253,
        "shikenId": 7,
        "no": 23,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "都市計画法において、市街化調整区域とは、おおむね10年以内に計画的に市街化を図るべき区域である。［2021年5月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 254,
        "shikenId": 7,
        "no": 24,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "不動産取得税は、相続人が不動産を相続により取得した場合には課されない。［2024年1月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 255,
        "shikenId": 7,
        "no": 25,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "「居住用財産を譲渡した場合の3,000万円の特別控除」は、自己が居住していた家屋を配偶者や子に譲渡した場合には、適用を受けることができない。［2022年9月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 256,
        "shikenId": 7,
        "no": 26,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "個人間において著しく低い価額で財産の譲渡が行われた場合、原則として、その譲渡があった時の譲渡財産の時価と支払った対価との差額に相当する金額について、贈与税の課税対象となる。［2020年9月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 257,
        "shikenId": 7,
        "no": 27,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "子が父親からの贈与により取得した財産について相続時精算課税の適用を受けた場合、その適用を受けた年以後、子は父親からの贈与により取得した財産について暦年課税を選択することはできない。［2021年9月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 258,
        "shikenId": 7,
        "no": 28,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "協議分割は、共同相続人全員の協議により遺産を分割する方法であり、その分割割合については、必ずしも法定相続分に従う必要はない。［2022年9月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 259,
        "shikenId": 7,
        "no": 29,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "相続人が負担した被相続人の葬式の際の香典返戻費用は、相続税の課税価格の計算上、葬式費用として控除することができる。［2021年5月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 260,
        "shikenId": 7,
        "no": 30,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "相続税額の計算において、「配偶者に対する相続税額の軽減」の適用を受けるためには、その適用を受けることにより納付すべき相続税額が算出されない場合であっても、相続税の申告書を提出しなければならない。［2022年9月］",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 261,
        "shikenId": 7,
        "no": 31,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "900万円を準備するために、15年間、毎年均等に積み立て、利率（年率）１％で複利運用する場合、必要となる毎年の積立金額は、下記の＜資料＞の係数を使用して算出すると（　　）である。［2020 年9 月］<br><br>＜資料＞利率（年率）１％・期間15年の各種係数<br><table class='btt'><tr><td>現価係数</td><td>資本回収係数</td><td>減債基金係数</td></tr><tr><td>0.8613</td><td>0.0721</td><td>0.0621</td></tr></table>",
        "choice1": "516,780円",
        "choice2": "558,900円",
        "choice3": "600,000円",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 262,
        "shikenId": 7,
        "no": 32,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "雇用保険の基本手当を受給するためには、倒産、解雇および雇止めなどの場合を除き、原則として、離職の日以前（①）に被保険者期間が通算して（②）以上あることなどの要件を満たす必要がある。［2022 年9 月］",
        "choice1": "①　１年間　　②　６カ月",
        "choice2": "①　２年間　　②　６カ月",
        "choice3": "①　２年間　　②　12カ月",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 263,
        "shikenId": 7,
        "no": 33,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "国民年金の被保険者が学生納付特例制度の適用を受けた期間は、その期間に係る保険料を追納しない場合、老齢基礎年金の受給資格期間（①）、老齢基礎年金の年金額（②）。［2021 年5 月］",
        "choice1": "①に算入され　　②にも反映される",
        "choice2": "①に算入されず　　②にも反映されない",
        "choice3": "①には算入されるが　　②には反映されない",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 264,
        "shikenId": 7,
        "no": 34,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "遺族厚生年金の額（中高齢寡婦加算額および経過的寡婦加算額を除く）は、原則として、死亡した者の厚生年金保険の被保険者記録を基礎として計算した老齢厚生年金の報酬比例部分の額の（　　）に相当する額である。［2022 年1 月］",
        "choice1": "２分の１",
        "choice2": "３分の２",
        "choice3": "４分の３",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 265,
        "shikenId": 7,
        "no": 35,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "住宅ローンの返済方法のうち、元利均等返済は、毎月の返済額が一定で、返済期間の経過とともに毎月の元金部分の返済額が（①）返済方法であり、総返済金額は、他の条件が同一である場合、通常、元金均等返済よりも（②）。［2023 年1 月］",
        "choice1": "①　減少する　　②　多い",
        "choice2": "①　増加する　　②　多い",
        "choice3": "①　増加する　　②　少ない",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 266,
        "shikenId": 7,
        "no": 36,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "生命保険会社が（　　）を引き上げた場合、通常、その後の終身保険の新規契約の保険料は安くなる。［2023 年5 月］",
        "choice1": "予定利率",
        "choice2": "予定死亡率",
        "choice3": "予定事業費率",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 267,
        "shikenId": 7,
        "no": 37,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "団体を契約者（＝保険料負担者）とし、その所属員を被保険者とする１年更新の定期保険であり、福利厚生規程等による保障の支払財源の確保を目的とした保険は、（　　）である。［2020 年1 月］",
        "choice1": "総合福祉団体定期保険",
        "choice2": "団体定期保険（Ｂグループ保険）",
        "choice3": "団体信用生命保険",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 268,
        "shikenId": 7,
        "no": 38,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "地震保険の保険金額は、火災保険の保険金額の30％から50％の範囲内で設定されるが、居住用建物については（①）、生活用動産（家財）については（②）が上限となる。［2022 年1 月］",
        "choice1": "①　1,500万円　　②300万円",
        "choice2": "①　3,000万円　　②500万円",
        "choice3": "①　5,000万円　　②1,000万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 269,
        "shikenId": 7,
        "no": 39,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "歩行中に交通事故でケガをし、加害車両の運転者が加入していた自動車保険の対人賠償保険から受け取った保険金は、所得税において、（　　）とされる。［2022 年9 月］",
        "choice1": "一時所得",
        "choice2": "雑所得",
        "choice3": "非課税所得",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 270,
        "shikenId": 7,
        "no": 40,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "リビング・ニーズ特約は、（①）、被保険者の余命が（②）以内と判断された場合に、所定の範囲内で死亡保険金の一部または全部を生前に受け取ることができる特約である。［2023 年1 月］",
        "choice1": "①　病気やケガの種類にかかわらず　　②　６カ月",
        "choice2": "①　病気やケガの種類にかかわらず　　②　１年",
        "choice3": "①　特定疾病に罹患したことが原因で　　②　１年",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 271,
        "shikenId": 7,
        "no": 41,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "景気動向指数において、（　　）は、一致系列に採用されている。 ［2021 年5 月］",
        "choice1": "完全失業率",
        "choice2": "新規求人数（除学卒）",
        "choice3": "有効求人倍率（除学卒）",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 272,
        "shikenId": 7,
        "no": 42,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "表面利率（クーポンレート）１％、残存期間２年の固定利付債券を額面100円当たり99円で購入した場合の最終利回り（年率・単利）は、（　　）である。なお、税金等は考慮しないものとし、計算結果は表示単位の小数点以下第３位を四捨五入している。［2022 年5 月］",
        "choice1": "1.50％",
        "choice2": "1.52％",
        "choice3": "2.02％",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 273,
        "shikenId": 7,
        "no": 43,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "追加型株式投資信託を基準価額１万4,000円で１万口購入した後、最初の決算時に１万口当たり300円の収益分配金が支払われ、分配落ち後の基準価額が１万3,800円となった場合、その収益分配金のうち、普通分配金は（①）であり、元本払戻金（特別分配金）は（②）である。［2021 年1 月］",
        "choice1": "①　０円　　②　300円",
        "choice2": "①　100円　　②　200円",
        "choice3": "①　200円　　②　100円",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 274,
        "shikenId": 7,
        "no": 44,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "個人向け国債は、金利の下限が年（①）とされ、購入単価は最低（②）から（②）単位である。［2020 年1 月］",
        "choice1": "①　0.03％　　②　10万円",
        "choice2": "①　0.05％　　②　１万円",
        "choice3": "①　0.05％　　②　10万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 275,
        "shikenId": 7,
        "no": 45,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "外貨預金の預入時において、預入金融機関が提示する（　　）は、預金者が円貨を外貨に換える際に適用される為替レートである。 ［2023 年9 月］",
        "choice1": "ＴＴＢ",
        "choice2": "ＴＴＭ",
        "choice3": "ＴＴＳ",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 276,
        "shikenId": 7,
        "no": 46,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "給与所得者が25年間勤務した会社を定年退職し、退職金2,000万円の支給を受けた場合、所得税における退職所得の金額の計算上、退職所得控除額は、（　　）となる。［2021 年5 月］",
        "choice1": "{800万円＋70万円×（25年－20年）}×１/２＝575万円",
        "choice2": "800万円＋40万円×（25年－20年）＝1,000万円",
        "choice3": "800万円＋70万円×（25年－20年）＝1,150万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 277,
        "shikenId": 7,
        "no": 47,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "日本国内において支払を受ける預貯金の利子は、原則として、所得税および復興特別所得税と住民税の合計で（ ① ）の税率による（ ② ）分離課税の対象となる。［2024 年1 月］",
        "choice1": "①　10.21％ 　　②　申告",
        "choice2": "①　20.315％　　②　申告",
        "choice3": "①　20.315％　　②　源泉",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 278,
        "shikenId": 7,
        "no": 48,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "A さんの本年分の各種所得の金額が下記の＜資料＞のとおりであった場合、損益通算後の総所得金額は（　　）となる。なお、各種所得の金額に付されている「▲」は、その所得に損失が生じていることを表すものとする。［2019 年9 月改題］<br><br>＜資料＞Aさんの各種所得の金額<br><table class='btt2'><tr><td>不動産所得の金額</td><td>750万円</td></tr><tr><td>雑所得の金額</td><td>▲50万円</td></tr><tr><td>事業所得の金額（株式等に係るものを除く）</td><td>▲150万円</td></tr></table>",
        "choice1": "550万円",
        "choice2": "600万円",
        "choice3": "700万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 279,
        "shikenId": 7,
        "no": 49,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "所得税において、控除対象扶養親族のうち、その年の12月31日時点の年齢が19歳以上23歳未満である特定扶養親族に係る扶養控除の額は、１人につき（　　）である。［2022 年5 月］",
        "choice1": "38万円",
        "choice2": "48万円",
        "choice3": "63万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 280,
        "shikenId": 7,
        "no": 50,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "年末調整の対象となる給与所得者は、年末調整により、（　　）の適用を受けることができる。［2020 年9 月改題］",
        "choice1": "生命保険料控除",
        "choice2": "雑損控除",
        "choice3": "医療費控除",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 281,
        "shikenId": 7,
        "no": 51,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "相続税路線価は、相続税や（①）を算定する際の土地等の評価額の基準となる価格であり、地価公示法による公示価格の（②）を価格水準の目安として設定される。［2022 年5 月］",
        "choice1": "①　不動産取得税　　②　70％",
        "choice2": "①　贈与税　　②　70％",
        "choice3": "①　贈与税　　②　80％",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 282,
        "shikenId": 7,
        "no": 52,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "不動産の売買契約において、買主が売主に解約手付を交付した場合、売主は、（①）が契約の履行に着手するまでは、受領した手付（②）を買主に提供することで、契約の解除をすることができる。［2022 年1 月］",
        "choice1": "①　買主　　②　と同額",
        "choice2": "①　買主　　②　の倍額",
        "choice3": "①　売主　　②　と同額",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 283,
        "shikenId": 7,
        "no": 53,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "建物の区分所有等に関する法律（区分所有法）上、集会においては、区分所有者および議決権の各（　　）以上の多数により、区分所有建物を取り壊し、その敷地上に新たに建物を建築する旨の決議（建替え決議）をすることができる。［2021 年9 月］",
        "choice1": "３分の２",
        "choice2": "４分の３",
        "choice3": "５分の４",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 284,
        "shikenId": 7,
        "no": 54,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "市街化区域内において、所有する農地を自宅の建築を目的として宅地に転用する場合、あらかじめ（　　）に届出をすれば都道府県知事等の許可は不要である。 ［2023 年5 月］",
        "choice1": "農業委員会",
        "choice2": "市町村長",
        "choice3": "国土交通大臣",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 285,
        "shikenId": 7,
        "no": 55,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "土地の有効活用方式のうち、一般に、土地所有者が土地の全部または一部を拠出し、デベロッパーが建設費等を拠出して、それぞれの出資比率に応じて土地・建物に係る権利を取得する方式を、（　　）という。［2020 年9 月］",
        "choice1": "等価交換方式",
        "choice2": "事業受託方式",
        "choice3": "建設協力金方式",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 286,
        "shikenId": 7,
        "no": 56,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "贈与税の申告書は、原則として、贈与を受けた年の翌年の（①）から３月15日までの間に、（②）の住所地を所轄する税務署長に提出しなければならない。［2022 年1 月］",
        "choice1": "①　２月１日　　②　受贈者",
        "choice2": "①　２月16日　　②　贈与者",
        "choice3": "①　２月16日　　②　受贈者",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 287,
        "shikenId": 7,
        "no": 57,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "個人が法人からの贈与により取得する財産は、（ 　　）の課税対象となる。［2023 年5 月］",
        "choice1": "法人税",
        "choice2": "贈与税",
        "choice3": "所得税",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 288,
        "shikenId": 7,
        "no": 58,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "公正証書遺言は、証人２人以上の立会いのもと、遺言者が遺言の趣旨を公証人に口授し、公証人がそれを筆記して作成される遺言であり、相続開始後に（①）における検認手続が（②）である。［2022 年5 月］",
        "choice1": "①家庭裁判所　　②不要",
        "choice2": "①家庭裁判所　　②必要",
        "choice3": "①公証役場　　②必要",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 289,
        "shikenId": 7,
        "no": 59,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "下記の＜親族関係図＞において、Aさんの相続における妻Bさんの法定相続分は、（　　）である。［2021 年9 月］<br><br>＜親族関係図＞<br><img src=\"/img/59.png\" style='width:470px'/>",
        "choice1": "２分の１",
        "choice2": "３分の２",
        "choice3": "４分の３",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 290,
        "shikenId": 7,
        "no": 60,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "自用地としての価額が5,000万円、借地権割合が70％、借家権割合が30％、賃貸割合が100％の貸家建付地の相続税評価額は、（　　）である。 ［2021 年1 月］",
        "choice1": "1,500万円",
        "choice2": "3,500万円",
        "choice3": "3,950万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 291,
        "shikenId": 8,
        "no": 1,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "公表された他人の著作物を自分の著作物に引用する場合の注意事項に関する次の記述のうち、最も不適切なものはどれか。",
        "question2": "",
        "choice1": "自らが作成する部分が「主」で、引用する部分が「従」となる内容にした。",
        "choice2": "自らが作成する部分と引用する部分を区別できないようにまとめて表現した。",
        "choice3": "引用する著作物のタイトルと著作者名を明記した。",
        "choice4": "",
        "choice5": "2023.09改",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 292,
        "shikenId": 8,
        "no": 2,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "下記は、小山家のキャッシュフロー表（一部抜粋）である。このキャッシュフロー表の空欄（ア）～（ウ）にあてはまる数値として、誤っているものはどれか。なお、計算過程においては端数処理をせず計算し、計算結果については万円未満を四捨五入すること。<br><img src='/img/8_1.png' style='max-width:680px'/><br>※年齢および金融資産残高は各年１２月３１日現在のものとし、２０×０年を基準年とする。<br>※給与収入は可処分所得で記載している。<br>※記載されている数値は正しいものとする。<br>※問題作成の都合上、一部を空欄にしてある。",
        "question2": "",
        "choice1": "（ア）３１０",
        "choice2": "（イ）２０３",
        "choice3": "（ウ）８４１",
        "choice4": "",
        "choice5": "2023.09改",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 293,
        "shikenId": 8,
        "no": 3,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "ＦＰの福岡さんは、以下の資金計画どおりにマンションを購入した後の荒木家のバランスシートを作成した。下表の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、＜資料＞に記載のあるデータに基づいて解答することとする。<br><img src='/img/8_2.png' style='max-width:680px'/>",
        "question2": "",
        "choice1": "１６０（万円）",
        "choice2": "６６０（万円）",
        "choice3": "１，０６０（万円）",
        "choice4": "",
        "choice5": "2020.01改",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 294,
        "shikenId": 8,
        "no": 4,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "荒木さんは、今後１０年間で毎年３６万円ずつ積立貯蓄をして、長女の教育資金を準備したいと考えている。積立期間中に年利２．０％で複利運用できるものとした場合、１０年後の合計金額として、正しいものはどれか。なお、下記＜資料＞の３つの係数の中から最も適切な係数を選択して計算し、解答に当たっては、千円未満を四捨五入すること。また、税金や記載のない事項については一切考慮しないこととする。<br><img src='/img/8_3.png' style='width:500px'/>",
        "question2": "",
        "choice1": "３，２３４，０００円",
        "choice2": "３，９４２，０００円",
        "choice3": "４，３８８，０００円",
        "choice4": "",
        "choice5": "2020.01改",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 295,
        "shikenId": 8,
        "no": 5,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "荒木浩介さんの家族構成と公的年金加入歴は下記のとおりである。仮に浩介さんが現時点（３３歳）で死亡した場合、浩介さんの死亡時点において妻の理恵さんに支給される公的年金の遺族給付に関する次の記述のうち、最も適切なものはどれか。なお、浩介さんは、入社時（２２歳）から死亡時まで厚生年金保険に加入しているものとし、遺族給付における生計維持要件は満たされているものとする。<br><img src='/img/8_4.png' style='max-width:680px'/>",
        "question2": "",
        "choice1": "遺族基礎年金と死亡一時金が支給される。",
        "choice2": "遺族厚生年金と寡婦年金が支給される。",
        "choice3": "遺族基礎年金と遺族厚生年金が支給される。",
        "choice4": "",
        "choice5": "2020.01改",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 296,
        "shikenId": 8,
        "no": 6,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "下記＜資料＞に基づくＱＸ株式会社の投資指標に関する次の記述のうち、最も適切なものはどれか。なお、購入時の手数料および税金は考慮しないこととする。<br><img src='/img/8_5.png' style='max-width:680px'/>",
        "question2": "",
        "choice1": "株価収益率（ＰＥＲ）で比較した場合、ＱＸ株式会社の株価は日経平均採用銘柄の平均（予想ベース）より割安である。",
        "choice2": "株価純資産倍率（ＰＢＲ）で比較した場合、ＱＸ株式会社の株価は東証プライム全銘柄の平均より割安である。",
        "choice3": "配当利回りで比較した場合、ＱＸ株式会社の配当利回りは東証グロース全銘柄の平均（予想ベース）より低い。",
        "choice4": "",
        "choice5": "2022.01",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 297,
        "shikenId": 8,
        "no": 7,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "関根さんは上場株式への投資に興味をもち、ＦＰの榎田さんに質問をした。下記の空欄（ア）～（ウ）にあてはまる語句に関する次の記述のうち、最も不適切なものはどれか。<br><img src='/img/8_6.png' style='max-width:680px'/>",
        "question2": "",
        "choice1": "空欄（ア）にあてはまる語句は、「 減少」である。",
        "choice2": "空欄（イ）にあてはまる語句は、「 高く」である。",
        "choice3": "空欄（ウ）にあてはまる語句は、「 株主優待制度」である。",
        "choice4": "",
        "choice5": "2022.01",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 298,
        "shikenId": 8,
        "no": 8,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "福岡さんはＱＳ投資信託を新規募集時に１，０００万口購入し、特定口座（源泉徴収口座）で保有して収益分配金を受け取っている。下記＜資料＞に基づき、福岡さんが保有するＱＳ投資信託に関する次の記述の空欄（ア）、（イ）にあてはまる語句の組み合わせとして、正しいものはどれか。<br><img src='/img/8_7.png' style='max-width:680px'/>",
        "question2": "",
        "choice1": "（ア）２４０，０００円　　（イ）普通分配金",
        "choice2": "（ア）１６０，０００円　　（イ）元本払戻金（特別分配金）",
        "choice3": "（ア）１６０，０００円　　（イ）普通分配金",
        "choice4": "",
        "choice5": "2022.01",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 299,
        "shikenId": 8,
        "no": 9,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "建築基準法に従い、下記＜資料＞の土地に建築物を建築する場合、その土地に対する建築物の建築面積の最高限度として、正しいものはどれか。なお、記載のない条件については一切考慮しないこととする。<br><img src='/img/8_8.png' style='max-width:680px'/>",
        "question2": "",
        "choice1": "３６０㎡",
        "choice2": "１，８００㎡",
        "choice3": "２，８８０㎡",
        "choice4": "",
        "choice5": "2020.09",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 300,
        "shikenId": 8,
        "no": 10,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "下記は、不動産の取得および保有に係る税金についてまとめた表である。下表の空欄（ア）～（ウ）にあてはまる語句の組み合わせとして、正しいものはどれか。<br><img src='/img/8_9.png' style='max-width:680px'/>",
        "question2": "",
        "choice1": "（ア）贈与　（イ）相続税評価額　　　（ウ）市町村（東京２３区は東京都）",
        "choice2": "（ア）相続　（イ）固定資産税評価額　（ウ）市町村（東京２３区は東京都）",
        "choice3": "（ア）贈与　（イ）固定資産税評価額　（ウ）都道府県",
        "choice4": "",
        "choice5": "2020.09",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 301,
        "shikenId": 8,
        "no": 11,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "都市計画法に基づく都市計画区域に関する下表の空欄（ア）～（ウ）にあてはまる数値または語句の組み合わせとして、最も適切なものはどれか。<br><img src='/img/8_10.png' style='max-width:680px'/>",
        "question2": "",
        "choice1": "（ア）  ５   （イ）抑制 （ウ）用途地域",
        "choice2": "（ア） １０  （イ）抑制 （ウ）区域区分",
        "choice3": "（ア） １０  （イ）調整 （ウ）区域区分",
        "choice4": "",
        "choice5": "2024.01",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 302,
        "shikenId": 8,
        "no": 12,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "露木忠則さんが加入している生命保険（下記＜資料＞参照）の保障内容に関する次の記述の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、保険契約は有効に継続しているものとし、特約は自動更新されているものとする。また、忠則さんはこれまでに＜資料＞の保険から保険金および給付金を一度も受け取っていないものとする。<br><img src='/img/8_11.png' style='max-width:680px'/>",
        "question2": "",
        "choice1": "５１５万円",
        "choice2": " ５２０万円",
        "choice3": "５２４万円",
        "choice4": "",
        "choice5": "2021.05改",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 303,
        "shikenId": 8,
        "no": 13,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "浅田和久さんが本年中に支払った生命保険の保険料は下記＜資料＞のとおりである。この場合の和久さんの本年分の所得税の計算における生命保険料控除の金額として、正しいものはどれか。なお、下記＜資料＞の保険について、これまでに契約内容の変更はないものとする。また、本年分の生命保険料控除額が最も多くなるように計算すること。<br><img src='/img/8_12.png' style='max-width:680px'/>",
        "question2": "",
        "choice1": " ３４，５８０円",
        "choice2": "４０，０００円",
        "choice3": "６０，１８０円",
        "choice4": "",
        "choice5": "2021.05改",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 304,
        "shikenId": 8,
        "no": 14,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "横川昭二さんが契約している普通傷害保険（個人賠償責任特約付帯）の内容は下記＜資料（一部抜粋）＞のとおりである。次の記述のうち、保険金の支払い対象となるものはどれか。なお、いずれも保険期間中に発生したものであり、＜資料＞に記載のない事項については一切考慮しないこととする。<br><img src='/img/8_13.png' style='max-width:680px'/>",
        "question2": "",
        "choice1": "昭二さんが徒歩で通勤する途中に他人の運転する車にはねられて死亡した場合。",
        "choice2": "昭二さんが自動車を運転中に、誤って歩行者に接触し、ケガをさせたことにより法律上の損害賠償責任を負った場合。",
        "choice3": " 昭二さんが地震により倒れてきた家財で肩を打撲し、通院した場合。",
        "choice4": "",
        "choice5": "2021.05改",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 305,
        "shikenId": 8,
        "no": 15,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "会社員の室井さんは、本年中に勤務先を定年退職した。室井さんの退職に係るデータが下記＜資料＞のとおりである場合、室井さんの所得税に係る退職所得の金額として、正しいものはどれか。なお、室井さんは役員であったことはなく、退職は障害者になったことに基因するものではない。また、前年以前に受け取った退職金はないものとする。<br><img src='/img/8_14.png' style='max-width:680px'/>",
        "question2": "",
        "choice1": "２，４４０万円",
        "choice2": "２，０６０万円",
        "choice3": "１，２２０万円",
        "choice4": "",
        "choice5": "2021.01改",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 306,
        "shikenId": 8,
        "no": 16,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "佐野さんは、個人でアパートの賃貸をしている青色申告者である。佐野さんの本年分の所得および所得控除が下記＜資料＞のとおりである場合、佐野さんの本年分の所得税額として、正しいものはどれか。なお、佐野さんに＜資料＞以外の所得はなく、復興特別所得税や税額控除、源泉徴収税額、予定納税等については一切考慮しないこととする。<br><img src='/img/8_15.png' style='max-width:680px'>",
        "question2": "",
        "choice1": "９１２，５００円",
        "choice2": "１，１５８，０００円",
        "choice3": "１，３４０，０００円",
        "choice4": "",
        "choice5": "2021.01改",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 307,
        "shikenId": 8,
        "no": 17,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "所得税の青色申告特別控除に関する次の記述の空欄（ア）～（ウ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。<br><img src='/img/8_16.png' style='max-width:680px'>",
        "question2": "",
        "choice1": "（ア）損益計算書　　（イ）１０万円　　（ウ）５５万円",
        "choice2": "（ア）損益計算書　　（イ）５５万円　　（ウ）６５万円",
        "choice3": "（ア）収支内訳書　　（イ）５５万円　　（ウ）６５万円",
        "choice4": "",
        "choice5": "2024.01",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 308,
        "shikenId": 8,
        "no": 18,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 5,
        "question1": "本年９月１日に相続が開始された宇野沙織さん（被相続人）の＜親族関係図＞が下記のとおりである場合、民法上の相続人および法定相続分の組み合わせとして、正しいものはどれか。なお、記載のない条件については一切考慮しないこととする。<br><img src='/img/8_17.png' style='max-width:680px'>",
        "question2": "",
        "choice1": "康史　１／２　　昭雄　１／４　　小百合　１／４",
        "choice2": "康史　２／３　　昭雄　１／６　　小百合　１／６",
        "choice3": "康史　２／３　　明人　１／３",
        "choice4": "",
        "choice5": "2022.09改",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 309,
        "shikenId": 8,
        "no": 19,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 5,
        "question1": "妹尾勇二さん（７８歳）は、将来発生するであろう自身の相続について、遺産分割等でのトラブルを防ぐために公正証書遺言の作成を検討しており、ＦＰの塩谷さんに相談をした。公正証書遺言に関する塩谷さんの次の説明のうち、最も適切なものはどれか。",
        "question2": "",
        "choice1": "「すでに作成した公正証書遺言を撤回したい場合、自筆証書遺言では撤回することはできません。」",
        "choice2": "「公正証書遺言を作成する場合、証人の立会いは必要ありません。」",
        "choice3": "「公正証書遺言を作成した場合、相続発生後、家庭裁判所に対してその検認を請求する必要はありません。」",
        "choice4": "",
        "choice5": "2022.09改",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 310,
        "shikenId": 8,
        "no": 20,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 5,
        "question1": "細川亜実さん（３２歳）が本年中に贈与を受けた財産の価額と贈与者は以下のとおりである。亜実さんの本年分の贈与税額として、正しいものはどれか。なお、本年中において、亜実さんはこれ以外の財産の贈与を受けておらず、相続時精算課税制度は選択していないものとする。<br><img src='/img/8_20.png' style='max-width:680px'>",
        "question2": "",
        "choice1": "４２５，０００円",
        "choice2": " ６２０，０００円",
        "choice3": " ７３０，０００円",
        "choice4": "",
        "choice5": "2022.09改",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 311,
        "shikenId": 9,
        "no": 1,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/311_1.png'/>",
        "question2": "はじめに、Mさんは、Aさんが老齢基礎年金の受給を65歳から開始した場合の年金額を試算した。Mさんが試算した老齢基礎年金の年金額の計算式として、次のうち最も適切なものはどれか。なお、老齢基礎年金の年金額は、本年度価額に基づいて計算するものとする。",
        "choice1": "<img src='/img/311_2.png' style=' height: 45px;margin-top: -10px;'/>",
        "choice2": "<img src='/img/311_3.png' style=' height: 45px;margin-top: -10px;margin-left:2px'/>",
        "choice3": "<img src='/img/311_4.png' style=' height: 47px;margin-top: -13px;margin-left:2px'/>",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "老齢基礎年金",
        "important": ""
    },
    {
        "id": 312,
        "shikenId": 9,
        "no": 2,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/311_1.png'/>",
        "question2": "次に、Mさんは、小規模企業共済制度について説明した。Mさんが、Aさんに対して説明した以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/312_1.png'/>",
        "choice1": "① 70,000 円　　② ２分の１相当額　　③ 一時所得",
        "choice2": "① 68,000 円　　② ２分の１相当額　　③ 退職所得",
        "choice3": "① 70,000 円　　② 全額　　　　　　　③ 退職所得",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "小規模企業共済",
        "important": ""
    },
    {
        "id": 313,
        "shikenId": 9,
        "no": 3,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/311_1.png'/>",
        "question2": "最後に、Mさんは、老後の年金収入を増やすことができる各種制度について説明した。MさんのAさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「国民年金の付加保険料を納付することで、将来の年金収入を増やすことができます。仮に、Aさんが付加保険料を120月納付し、65歳から老齢基礎年金を受給する場合は、年額48,000円の付加年金を受給することができます」",
        "choice2": "「国民年金基金は、国民年金の第１号被保険者の老齢基礎年金に上乗せする年金を支給する任意加入の年金制度です。加入は口数制となっており、１口目は２種類の終身年金（A型・B型）のいずれかを選択します」",
        "choice3": "「Aさんが確定拠出年金の個人型年金に加入する場合、国民年金の付加保険料の納付および国民年金基金への加入はできません」",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "付加年金と確定拠出年金",
        "important": ""
    },
    {
        "id": 314,
        "shikenId": 9,
        "no": 4,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/314_1.png'/>",
        "question2": "Ｍさんは、《設例》の米ドル建定期預金について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「米ドル建定期預金の満期時の為替レートが、預入時の為替レートに比べて円高・米ドル安となった場合、円換算の運用利回りは向上します」",
        "choice2": "「Ｘ銀行に預け入れた米ドル建定期預金は、金額の多寡にかかわらず、預金保険制度の保護の対象となりません」",
        "choice3": "「Ｘ銀行の米ドル建定期預金に10,000米ドルを預け入れた場合、Ａさんが満期時に受け取ることができる利息額は400米ドル（税引前）になります」",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "外貨預金の性質",
        "important": ""
    },
    {
        "id": 315,
        "shikenId": 9,
        "no": 5,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/314_1.png'/>",
        "question2": "Ａさんが、《設例》および下記の＜資料＞の条件で、10,000米ドルを預け入れ、満期時に円貨で受け取った場合における元利金の合計額として、次のうち最も適切なものはどれか。なお、計算にあたっては税金等を考慮せず、預入期間６カ月は0.5年として計算すること。<br><img src='/img/315_1.png'/>",
        "choice1": "1,326,000 円",
        "choice2": "1,331,100 円",
        "choice3": "1,336,200 円",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "外貨預金の計算",
        "important": ""
    },
    {
        "id": 316,
        "shikenId": 9,
        "no": 6,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/314_1.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、《設例》の米ドル建定期預金に係る課税関係について説明した。Ｍさんが説明した以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/316_1.png'/>",
        "choice1": "①源泉分離課税　　②雑所得　　　③できません",
        "choice2": "①源泉分離課税　　②一時所得　　③できます",
        "choice3": "①申告分離課税　　②雑所得　　　③できます",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "外貨預金の課税関係",
        "important": ""
    },
    {
        "id": 317,
        "shikenId": 9,
        "no": 7,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/317_1.png'/>",
        "question2": "Aさんの本年分の所得税における総所得金額は、次のうちどれか。<br><img src='/img/318_1.png'/>",
        "choice1": "628 万円",
        "choice2": "728 万円",
        "choice3": "920 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "総所得金額の計算",
        "important": ""
    },
    {
        "id": 318,
        "shikenId": 9,
        "no": 8,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/317_1.png'/>",
        "question2": "Aさんの本年分の所得税における所得控除に関する以下の文章の空欄①～③ に入る数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/319_1.png'/>",
        "choice1": "① 38 　　② 26　　③ 76",
        "choice2": "① 48 　　② 38　　③ 101",
        "choice3": "① 103　　② 38　　③ 63",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "所得控除",
        "important": ""
    },
    {
        "id": 319,
        "shikenId": 9,
        "no": 9,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/317_1.png'/>",
        "question2": "Aさんの本年分の所得税の確定申告に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「不動産所得の金額が20万円を超えるため、Aさんは所得税の確定申告をしなければなりません」",
        "choice2": "「Aさんは、所得税の確定申告をすることで、ふるさと納税で寄附した10万円の全額について、本年分の所得税額から控除されます」",
        "choice3": "「確定申告書は、原則として、翌年２月16日から３月15日までの間にAさんの住所地を所轄する税務署長に提出してください」",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "確定申告",
        "important": ""
    },
    {
        "id": 320,
        "shikenId": 9,
        "no": 10,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/320_1.png'/>",
        "question2": "甲土地に賃貸マンション（耐火建築物）を建築する場合の①建蔽率の上限となる建築面積と②容積率の上限となる延べ面積の組合せとして、次のうち最も適切なものはどれか。",
        "choice1": "① 630㎡　　　② 2,700㎡",
        "choice2": "① 720㎡　　　② 2,160㎡",
        "choice3": "① 720㎡　　　② 2,700㎡",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "建蔽率と容積率の計算",
        "important": ""
    },
    {
        "id": 321,
        "shikenId": 9,
        "no": 11,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/320_1.png'/>",
        "question2": "甲土地の有効活用に関する以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/321_1.png'/>",
        "choice1": "①借地権割合×賃貸割合　　②70　　③400",
        "choice2": "①（１－借地権割合×賃貸割合）　　②70　　③200",
        "choice3": "①（１－借地権割合×借家権割合×賃貸割合）　　②60　　③200",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "宅地の評価",
        "important": ""
    },
    {
        "id": 322,
        "shikenId": 9,
        "no": 12,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/320_1.png'/>",
        "question2": "甲土地の有効活用に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「等価交換方式により、マンションを建築する手法が考えられます。Aさんとしては、自己資金を使わず、マンション住戸を取得することができます」",
        "choice2": "「事業用定期借地権方式により、甲土地を一定期間賃貸する手法が考えられます。甲土地を手放さず、安定した地代収入を得ることができます」",
        "choice3": "「建設協力金方式により、甲土地上に建築した店舗をテナントに貸し出す手法が考えられます。契約期間満了後、借主であるテナントが建物を撤去し、甲土地は更地で返還されます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "土地の有効活用",
        "important": ""
    },
    {
        "id": 323,
        "shikenId": 9,
        "no": 13,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/323_1.png'/>",
        "question2": "Aさんの相続に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「相続人が自宅に保管されていたAさんの自筆証書遺言を発見した場合、相続人は、遅滞なく、自筆証書遺言を法務局に提出して、その検認を請求しなければなりません」",
        "choice2": "「Aさんが本年分の所得税および復興特別所得税について確定申告書を提出しなければならない場合に該当するとき、相続人は、原則として、相続の開始があったことを知った日の翌日から４カ月以内に準確定申告書を提出しなければなりません」",
        "choice3": "「相続税の申告書は、原則として、相続の開始があったことを知った日の翌日から10カ月以内に被相続人であるAさんの死亡時の住所地を所轄する税務署長に提出しなければなりません」",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続の基礎知識",
        "important": ""
    },
    {
        "id": 324,
        "shikenId": 9,
        "no": 14,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/323_1.png'/>",
        "question2": "Aさんの相続に関する以下の文章の空欄①～③に入る数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/324_1.png'/>",
        "choice1": "① 4,200　　② 4,500　　③ 4,000",
        "choice2": "① 4,800　　② 1,500　　③ 4,000",
        "choice3": "① 4,800　　② 4,500　　③ 1,000",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "課税価格の計算におけるルール",
        "important": ""
    },
    {
        "id": 325,
        "shikenId": 9,
        "no": 15,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/323_1.png'/>",
        "question2": "Aさんの相続に係る課税遺産総額（「 課税価格の合計額  遺産に係る基礎控除額」） が２億9 , 000 万円であった場合の相続税の総額は、次のうちどれか。<br><img src='/img/325_1.png'/>",
        "choice1": "7,050 万円",
        "choice2": "9,050 万円",
        "choice3": "１億350 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続税額の計算",
        "important": ""
    },
    {
        "id": 326,
        "shikenId": 10,
        "no": 1,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/326_1.png'/>",
        "question2": "はじめに、Mさんは、《設例》の＜Aさん夫妻に関する資料＞に基づき、Aさんおよび妻Bさんが老齢基礎年金の受給を65歳から開始した場合の年金額（本年度価額）を試算した。Mさんが試算した老齢基礎年金の年金額の計算式の組合せとして、次のうち最も適切なものはどれか。",
        "choice1": "<img src='/img/326_2.png' style=' height: 45px;margin-top: -10px;'/>",
        "choice2": "<img src='/img/326_3.png' style=' height: 45px;margin-top: -10px;'/>",
        "choice3": "<img src='/img/326_4.png' style=' height: 45px;margin-top: -11px;'/>",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "老齢基礎年金",
        "important": ""
    },
    {
        "id": 327,
        "shikenId": 10,
        "no": 2,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/326_1.png'/>",
        "question2": "次に、Mさんは、老齢基礎年金の繰上げ支給および繰下げ支給について説明した。Mさんが、Aさんに対して説明した以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/327_1.png'/>",
        "choice1": "① 65　　② 30　　③ しなければなりません",
        "choice2": "① 66　　② 30　　③ するかどうか選択することができます",
        "choice3": "① 66　　② 42　　③ しなければなりません",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "繰上げ支給と繰下げ支給",
        "important": ""
    },
    {
        "id": 328,
        "shikenId": 10,
        "no": 3,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/326_1.png'/>",
        "question2": "最後に、Mさんは、Aさんおよび妻Bさんが受給することができる公的年金制度からの老齢給付について説明した。MさんのAさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「Aさんには国民年金の未加入期間がありますが、60歳から65歳になるまでの間、その未加入期間に相当する月数について、国民年金に任意加入して保険料を納付した場合、老齢基礎年金の年金額を増額することができます」",
        "choice2": "「Aさんが65歳から受給することができる老齢厚生年金の額には、配偶者の加給年金額が加算されます」",
        "choice3": "「妻Bさんは、原則として64歳から報酬比例部分のみの特別支給の老齢厚生年金を受給することができます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "老齢給付",
        "important": ""
    },
    {
        "id": 329,
        "shikenId": 10,
        "no": 4,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/329_1.png'/>",
        "question2": "はじめに、Mさんは、現時点の必要保障額を試算することにした。下記の＜算式＞および＜条件＞に基づき、Aさんが現時点で死亡した場合の必要保障額は、次のうちどれか。<br><img src='/img/329_2.png'/>",
        "choice1": "4,004 万円",
        "choice2": "6,004 万円",
        "choice3": "１億3,504 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク（個人）",
        "rontenName": "必要保障額の計算",
        "important": ""
    },
    {
        "id": 330,
        "shikenId": 10,
        "no": 5,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/329_1.png'/>",
        "question2": "次に、Mさんは、生命保険の加入について説明した。MさんのAさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「Aさんが提案を受けた生命保険の死亡保障の金額は、現時点での必要保障額をカバーしていません。どの程度の死亡保障を準備するか、支出可能な保険料を把握したうえでご検討ください」",
        "choice2": "「生命保険は、一度加入したら終わりではありません。必要保障額は、通常、お子さまの成長とともに逓増していきますので、ライフイベントに合わせて、保障内容を定期的に見直すことが大切です」",
        "choice3": "「保障金額や保障内容を準備するうえでは、目的に応じた加入をされることをお勧めします。例えば、Aさんの葬儀費用やお子さまの教育資金は終身保険や定期保険特約等の一時金タイプで準備し、残されたご家族の生活費は収入保障特約等の年金タイプで準備することなどが考えられます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク（個人）",
        "rontenName": "生命保険の見直し",
        "important": ""
    },
    {
        "id": 331,
        "shikenId": 10,
        "no": 6,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/329_1.png'/>",
        "question2": "最後に、Mさんは、Aさんが提案を受けた生命保険の保障内容について説明した。MさんのAさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「Aさんが、がんに罹患した場合、三大疾病一時金特約から200万円を受け取ることができます。ただし、通常、がんの保障については契約日から４カ月間の免責期間があります」",
        "choice2": "「Aさんが提案を受けた生命保険には、総合医療特約が付加されていますが、がん保障に特化したものや、入院１日目（日帰り入院）から相応の一時金が支払われるものなど、Aさんのニーズに合わせて医療保障を充実させることも検討事項の１つになります」",
        "choice3": "「Aさんが、厚生労働大臣が定めた先進医療による療養を受けた場合、先進医療特約から先進医療給付金を受け取ることができます。また、所定の先進医療については、一部の医療機関において、保険会社から医療機関へ直接技術料を支払う制度もありますので、特約に関する内容をご確認ください」",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク（個人）",
        "rontenName": "第三分野の保険",
        "important": ""
    },
    {
        "id": 332,
        "shikenId": 10,
        "no": 7,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/332_1.png'/>",
        "question2": "仮に、将来X社がAさんに役員退職金4,000万円を支給した場合、Aさんが受け取る役員退職金に係る退職所得の金額として、次のうち最も適切なものはどれか。なお、Aさんの役員在任期間（勤続年数）を30年とし、これ以外に退職手当等の収入はなく、障害者になったことが退職の直接の原因ではないものとする。",
        "choice1": "1,200 万円",
        "choice2": "1,250 万円",
        "choice3": "2,500 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク（法人）",
        "rontenName": "退職所得の計算",
        "important": ""
    },
    {
        "id": 333,
        "shikenId": 10,
        "no": 8,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/332_1.png'/>",
        "question2": "Mさんは、《設例》の＜資料＞の終身保険について説明した。MさんのAさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「当該終身保険は、保険料払込期間における解約返戻金額を抑えることで、低解約返戻金型ではない終身保険と比較して保険料が割安となっています」",
        "choice2": "「Aさんの退任時に、役員退職金の一部として当該終身保険の契約者をAさん、死亡保険金受取人をAさんの相続人に名義変更することで、当該終身保険を個人の保険として継続することが可能です」",
        "choice3": "「保険期間中に急な資金需要が発生した際、契約者貸付制度を利用することにより、当該終身保険契約を解約することなく、資金を調達することができます。なお、契約者貸付金は、雑収入として益金の額に算入します」",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク（法人）",
        "rontenName": "法人の生命保険商品",
        "important": ""
    },
    {
        "id": 334,
        "shikenId": 10,
        "no": 9,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/332_1.png'/>",
        "question2": "《設例》の＜資料＞の終身保険を下記＜条件＞で解約した場合の経理処理（仕訳）として、次のうち最も適切なものはどれか。<br><br>＜条件＞<br>・低解約返戻金期間経過後に解約し、受け取った解約返戻金額は4,600万円である。<br>・X社が解約時までに支払った保険料の総額は4,400万円である。<br>・上記以外の条件は考慮しないものとする。",
        "choice1": "<img src='/img/334_1.png' style='width:500px'/>",
        "choice2": "<img src='/img/334_2.png' style='width:500px'/>",
        "choice3": "<img src='/img/334_3.png' style='width:500px'/>",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク（法人）",
        "rontenName": "法人の生命保険の経理処理",
        "important": ""
    },
    {
        "id": 335,
        "shikenId": 10,
        "no": 10,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 4,
        "question1": "<img src='/img/335_1.png'/>",
        "question2": "Aさんの本年分の所得税における総所得金額は、次のうちどれか。<br><img src='/img/335_2.png'/>",
        "choice1": "592 万円",
        "choice2": "642 万円",
        "choice3": "780 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "総所得金額の計算",
        "important": ""
    },
    {
        "id": 336,
        "shikenId": 10,
        "no": 11,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 3,
        "question1": "<img src='/img/335_1.png'/>",
        "question2": "Aさんの本年分の所得税の課税に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「  Ａ  さんが受け取った一時払変額個人年金保険の解約返戻金は、源泉分離課税の対象となります」",
        "choice2": " 「  Ａ  さんが適用を受けることができる配偶者控除の額は、38 万円です」",
        "choice3": " 「  Ａ  さんが適用を受けることができる扶養控除の額は、63 万円です」",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "所得税の課税",
        "important": ""
    },
    {
        "id": 337,
        "shikenId": 10,
        "no": 12,
        "answerType": 2,
        "groupId": 0,
        "answer": 1,
        "score": 3,
        "question1": "<img src='/img/335_1.png'/>",
        "question2": "住宅借入金等特別控除に関する以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br><img src='/img/337_1.png'/>",
        "choice1": "①　0.7　　　②　13　　　③　住所地",
        "choice2": "①　1.0　　　②　10　　　③　住所地",
        "choice3": "①　0.7　　　②　10　　　③　勤務地",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "住宅借入金等特別控除",
        "important": ""
    },
    {
        "id": 338,
        "shikenId": 10,
        "no": 13,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/338_1.png'/>",
        "question2": "生前贈与に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「Aさんが長女Cさんに現金を贈与し、長女Cさんが暦年課税を選択した場合、その年にAさんから長女Cさんへ贈与した財産の価額が贈与税の基礎控除額を超えるときは、贈与したAさんが贈与税の申告書を提出しなければなりません」",
        "choice2": "「Aさんが長女Cさんに現金を贈与し、長女Cさんが相続時精算課税制度を選択した場合、累計で3,000万円までの贈与について贈与税は課されません」",
        "choice3": "「Aさんが長女Cさんに現金を贈与し、長女Cさんが相続時精算課税制度を選択した場合、その選択をした年分以降にAさんから長女Cさんへ贈与する財産について、暦年課税へ変更することはできません」",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "贈与税の基礎知識",
        "important": ""
    },
    {
        "id": 339,
        "shikenId": 10,
        "no": 14,
        "answerType": 2,
        "groupId": 0,
        "answer": 3,
        "score": 3,
        "question1": "<img src='/img/338_1.png'/>",
        "question2": "Aさんの相続等に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「妻Bさんが自宅の敷地を相続により取得し、『小規模宅地等についての相続税の課税価格の計算の特例』の適用を受けた場合、自宅の敷地について相続税の課税価格に算入すべき価額は5,600万円となります」",
        "choice2": "「円滑な遺産分割のための手段として遺言書の作成をお勧めします。自筆証書遺言は、その遺言の全文および財産目録をパソコンで作成し、日付および氏名を自書して押印することで作成することができます」",
        "choice3": "「契約者（＝保険料負担者）および被保険者をAさん、死亡保険金受取人を推定相続人とする終身保険に加入することをお勧めします。死亡保険金受取人が受け取る死亡保険金は、『500万円×法定相続人の数』を限度として、死亡保険金の非課税金額の規定の適用を受けることができます」",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続税の基礎知識",
        "important": ""
    },
    {
        "id": 340,
        "shikenId": 10,
        "no": 15,
        "answerType": 2,
        "groupId": 0,
        "answer": 2,
        "score": 4,
        "question1": "<img src='/img/338_1.png'/>",
        "question2": "仮に、Aさんの相続が現時点（本年５月23日）で開始し、Aさんの相続に係る課税遺産総額（課税価格の合計額－遺産に係る基礎控除額）が9,000万円であった場合の相続税の総額は、次のうちどれか。<br><img src='/img/340_1.png' style='width:430px;'/>",
        "choice1": "1,200 万円",
        "choice2": "1,275 万円",
        "choice3": "2,000 万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続税額の計算",
        "important": ""
    }
]